import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http'
import { inject } from '@angular/core'
import { finalize } from 'rxjs'
import { LoadingService } from '../services/loading.service'
import { ConfigService } from '../services/config.service'

export const httpInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn) => {
    const loaderService = inject(LoadingService)
    const configService = inject(ConfigService)

    // add header to request
    req = req.clone({
        setHeaders: {
            'X-Company-Api-Key': configService.apiKey() || '',
        },
    })

    if (req.urlWithParams.indexOf('skipGlobalLoading=true') > -1) {
        req.params.delete('skipGlobalLoading')
        return next(req)
    }

    loaderService.loadingOn()

    return next(req).pipe(finalize(() => loaderService.loadingOff()))
}
