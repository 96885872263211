import { Injectable } from '@angular/core'
import { FinanceOption } from './finance-price.interface'

@Injectable()
export class FinanceCalculatorService {
    constructor() {}

    calculate(
        price: number,
        depositPercentage: number,
        apr: number,
        financePeriod: number,
        financeInterestFreePeriod: number,
        selectedFinancePeriod: number
    ): FinanceOption {
        const totalAmount = price - (price * depositPercentage) / 100
        const deposit = (price * depositPercentage) / 100

        const monthlyPayment = this.getMonthlyCostWithApr(
            price,
            deposit,
            apr,
            financePeriod,
            financeInterestFreePeriod,
            selectedFinancePeriod
        )
        const totalAmountWithApr = monthlyPayment * (selectedFinancePeriod * 12) + deposit
        const totalOnFinance = price - deposit

        return {
            apr: selectedFinancePeriod > financeInterestFreePeriod ? apr : 0,
            totalAmount,
            depositAmount: deposit,
            monthlyCost: monthlyPayment,
            totalAmountWithApr,
            totalOnFinance,
        }
    }

    private getMonthlyCostWithApr(
        price: number,
        deposit: number,
        apr: number,
        interestBearingFinancePeriod: number,
        interestFreePeriod: number,
        selectedFinancePeriod: number
    ) {
        const p = price - deposit
        const j = apr / 12 / 100
        const n = selectedFinancePeriod * 12

        if (selectedFinancePeriod > interestFreePeriod) {
            return p * (j / (1 - Math.pow(1 + j, n * -1)))
        } else {
            return p / n
        }
    }
}
