import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class StripeService {
    #httpClient = inject(HttpClient)

    markPaymentAsPaid(request: { sessionId: string; cid: number }): Observable<void> {
        return this.#httpClient.post<void>(`${environment.baseUrl}payments/stripe/success`, request)
    }
}
