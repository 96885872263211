<div class="card-body">
    <div class="d-flex align-items-center mb-2">
        <div>
            <fa-icon class="text-primary" [icon]="[prefix, icon]" size="2x"></fa-icon>
        </div>
        <div class="ms-3">
            <h5 class="card-title mb-1">{{ name }}</h5>
            @if (subtitle) {
                <p class="card-subtitle mb-0 text-body-secondary">{{ subtitle }}</p>
            }
        </div>
    </div>
    <ng-content></ng-content>
</div>
