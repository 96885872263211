import { Component, Input, inject } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { HeaderComponent } from 'projects/quoting-app/src/app/layout/header/header.component'
import { ProgressComponent } from 'projects/quoting-app/src/app/layout/progress/progress.component'
import { AirQuoteStore } from '../../air-quote.store'
import { JsonPipe } from '@angular/common'

@Component({
    selector: 'sf-air-quote-shell',
    standalone: true,
    imports: [RouterOutlet, HeaderComponent, ProgressComponent, JsonPipe],
    templateUrl: './air-quote-shell.component.html',
    styleUrl: './air-quote-shell.component.scss',
})
export class AirQuoteShellComponent {
    airQuoteStore = inject(AirQuoteStore)

    @Input()
    set id(id: string) {
        if (!id) return

        if (Number(id) === 0) {
            this.airQuoteStore.initializeAirQuote()
        } else {
            this.airQuoteStore.loadExistingQuote(id)
        }
    }
}
