<sf-label for="appointmentSelector" label="Select your appointment type" [required]="isRequired"></sf-label>
<div
    class="appointment-type__list"
    [class.is-invalid]="control.invalid"
    [class.ng-touched]="control.touched"
    [class.ng-dirty]="control.dirty">
    @for (option of options; track option.appointmentTypeId) {
        @if (option.showOnPage) {
            <div
                class="appointment-type__option rounded-2 border-2 border-primary"
                [class.appointment-type__option--selected]="
                    option.appointmentTypeId === selectedOption?.appointmentTypeId
                "
                [class.border-success]="option.appointmentTypeId === selectedOption?.appointmentTypeId"
                [class.text-success]="option.appointmentTypeId === selectedOption?.appointmentTypeId"
                (click)="valueChanged(option)"
                role="button">
                <div class="appointment-type__image">
                    <img class="img-fluid" src="{{ option.icon }}" alt="{{ option.name }} icon" />
                </div>
                <div class="appointment-type__name fw-bold fs-5 ms-3">
                    {{ option.name }}
                </div>
            </div>
        }
    }
</div>

@if (control.touched && control.dirty && control.errors) {
    @for (err of control.errors | keyvalue; track err) {
        <div class="invalid-feedback">
            {{ errorMessages[err.key] }}
        </div>
    }
}
