@if (customer(); as customer) {
    <div class="card">
        <!-- <div class="card-header fw-bold">We found your details!</div> -->
        <div class="card-body fw-bold">
            <h5 class="card-title">{{ customer.prettyName }}</h5>
            <p class="mb-0">Address: {{ customer.fullAddress }}</p>
            <p class="mb-0">
                Phone: {{ customer.mobileNumber || customer.homeNumber || customer.otherNumber || 'N/A' }}
            </p>
            <p class="mb-0">Email: {{ customer.emailAddress || 'N/A' }}</p>
        </div>
    </div>
} @else {
    <p>No customer was found. Try again.</p>
}
