@if (config(); as config) {
    <div class="bottom-footer">
        <div class="container">
            <div class="d-flex flex-column flex-md-row gap-3 gap-md-0 align-items-center justify-content-between">
                <div>
                    <p class="mb-0 small">
                        Built by
                        <a href="https://www.surefiresoftware.co.uk/" target="_blank">Surefire Software</a> ©
                        {{ year }}
                    </p>
                </div>
                <div>
                    <a href="https://www.surefiresoftware.co.uk/" target="_blank">
                        <img
                            class="img-fluid"
                            style="max-height: 30px"
                            src="/assets/images/surefire-logo.svg"
                            alt="Surefire Logo" />
                    </a>
                </div>
                <div>
                    <a class="small" href="https://www.surefiresoftware.co.uk/privacy-policy/" target="_blank"
                        >Privacy Policy</a
                    >
                </div>
            </div>
        </div>
    </div>
}
