import { Component } from '@angular/core'

@Component({
    selector: 'div[sf-footer]',
    standalone: true,
    imports: [],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss',
    host: {
        class: 'd-block py-5',
    },
})
export class FooterComponent {}
