import { JsonPipe, AsyncPipe, DatePipe } from '@angular/common'
import { Component, ElementRef, OnInit, ViewChild, inject, signal } from '@angular/core'
import { FormGroup, FormControl, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms'
import { ActivatedRoute, Router, RouterLink } from '@angular/router'
import { NgbDatepickerModule, NgbAlertModule, NgbDate, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select'
import { ReCaptchaV3Service, RecaptchaV3Module } from 'ng-recaptcha'
import { ConfigService } from 'projects/quoting-app/src/app/services/config.service'
import { CustomerService } from 'projects/quoting-app/src/app/services/customer.service'
import { PostcodeService } from 'projects/quoting-app/src/app/services/postcode.service'
import { CustomerCardComponent } from 'projects/quoting-app/src/app/shared/components/customer-card/customer-card.component'
import { ExistingCustomerCheckerComponent } from 'projects/quoting-app/src/app/shared/components/existing-customer-checker/existing-customer-checker.component'
import { ButtonComponent } from 'projects/quoting-app/src/app/shared/components/form/button/button.component'
import { FloatingLabelPostcodeInputComponent } from 'projects/quoting-app/src/app/shared/components/form/floating-label-postcode-input/floating-label-postcode-input.component'
import { FloatingLabelTextareaInputComponent } from 'projects/quoting-app/src/app/shared/components/form/floating-label-textarea-input/floating-label-textarea-input.component'
import { InputTextareaComponent } from 'projects/quoting-app/src/app/shared/components/form/input-textarea/input-textarea.component'
import { InputComponent } from 'projects/quoting-app/src/app/shared/components/form/input/input.component'
import { LabelComponent } from 'projects/quoting-app/src/app/shared/components/form/label/label.component'
import { PostcodeLookupComponent } from 'projects/quoting-app/src/app/shared/components/postcode-lookup/postcode-lookup.component'
import { Customer } from 'projects/quoting-app/src/app/shared/interfaces/customer/customer.interface'
import { PostcodeSuggestion } from 'projects/quoting-app/src/app/shared/interfaces/postcode/postcode-suggestion.interface'
import { timeGreaterThanMidnightValidator } from 'projects/quoting-app/src/app/shared/validators/greater-than-midnight.validator'
import { UKPostcodeValidator } from 'projects/quoting-app/src/app/shared/validators/postcode.validator.validator'
import {
    Observable,
    catchError,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    of,
    startWith,
    switchMap,
    tap,
} from 'rxjs'
import { BookAppointmentStore } from './book-appointment.store'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'
import { InputPostcodeComponent } from 'projects/quoting-app/src/app/shared/components/form/input-postcode/input-postcode.component'
import { BookingEngineAvailableTime } from 'projects/quoting-app/src/app/shared/interfaces/booking-engine/booking-engine-available-times.interface'
import { dateWithoutTimezone } from 'projects/sf-shared-lib/src/lib/date-without-timezone'
import { BookingEngineMakeBooking } from 'projects/quoting-app/src/app/shared/interfaces/booking-engine/booking-engine-make-booking.interface'
import { BookingEngineService } from 'projects/quoting-app/src/app/services/booking-engine.service'
import { BookingEngineResponse } from 'projects/quoting-app/src/app/shared/interfaces/booking-engine/booking-engine-booking-response.interface'
import { CtaMobileAppVerticalComponent } from 'projects/quoting-app/src/app/shared/components/cta-mobile-app-vertical/cta-mobile-app-vertical.component'
import { TitleService } from 'projects/quoting-app/src/app/services/title.service'
import { AppointmentRadioSelectorComponent } from '../../components/appointment-radio-selector/appointment-radio-selector.component'
import { LoaderComponent } from 'projects/sf-quoting-lib/src/public-api'

type BookAppointmentStage = 'preform' | 'form'

@Component({
    selector: 'sf-book-appointment',
    standalone: true,
    imports: [
        RouterLink,
        RecaptchaV3Module,
        ReactiveFormsModule,
        NgSelectModule,
        NgbDatepickerModule,
        NgbAlertModule,
        AppointmentRadioSelectorComponent,
        CtaMobileAppVerticalComponent,
        CustomerCardComponent,
        ButtonComponent,
        LabelComponent,
        InputComponent,
        InputPostcodeComponent,
        InputTextareaComponent,
        PostcodeLookupComponent,
        FloatingLabelTextareaInputComponent,
        FloatingLabelPostcodeInputComponent,
        ExistingCustomerCheckerComponent,
        PostcodeLookupComponent,
        LoaderComponent,
        JsonPipe,
        AsyncPipe,
        DatePipe,
    ],
    templateUrl: './book-appointment.component.html',
    styleUrl: './book-appointment.component.scss',
    providers: [DatePipe],
})
export class BookAppointmentComponent implements OnInit {
    bookAppointmentStore = inject(BookAppointmentStore)

    #bookingEngineService = inject(BookingEngineService)
    #configService = inject(ConfigService)
    #customerService = inject(CustomerService)
    #datePipe = inject(DatePipe)
    #modalService = inject(NgbModal)
    #fb = inject(FormBuilder)
    #postcodeService = inject(PostcodeService)
    #recaptchaService = inject(ReCaptchaV3Service)
    #route = inject(ActivatedRoute)
    #router = inject(Router)
    #titleService = inject(TitleService)

    @ViewChild('top', { static: false }) top!: ElementRef
    @ViewChild('confirmation', { static: false }) confirmation!: ElementRef
    @ViewChild('datepicker', { static: false }) datepicker!: ElementRef
    @ViewChild('timepicker', { static: false }) timepicker!: ElementRef
    @ViewChild('formfields', { static: false }) formFields!: ElementRef
    @ViewChild('addressSelector', { static: false }) addressSelector!: NgSelectComponent

    customer$ = toObservable(this.bookAppointmentStore.customer)

    titles$ = this.#titleService.getTitles().pipe(map(titles => titles.filter(title => !!title.title)))

    config = this.#configService.config

    stage = signal<BookAppointmentStage>('preform')
    customer = signal<Customer | null>(null)
    editableAppointmentType = signal<boolean>(true)

    hoveredDate: NgbDate | null = null
    addressSuggestions = signal<PostcodeSuggestion[]>([])
    postcodeError = signal<string | null>(null)
    selectedTime = signal<BookingEngineAvailableTime | null>(null)
    bookingEngineResponse = signal<BookingEngineResponse | null>(null)

    preform = this.#fb.group({
        mobileNumber: this.#fb.nonNullable.control<string>('', [Validators.required, Validators.maxLength(15)]),
        postcode: this.#fb.nonNullable.control<string>('', [
            Validators.required,
            Validators.maxLength(10),
            UKPostcodeValidator(),
        ]),
        appointmentTypeId: this.#fb.control<number | null>(null, [Validators.required]),
    })

    form: EnquiryForm = this.#fb.group({
        customerApiKey: this.#fb.control<string | null>(null),
        title: this.#fb.nonNullable.control<string>('', [Validators.required]),
        firstName: this.#fb.nonNullable.control<string>('', [Validators.required, Validators.maxLength(30)]),
        lastName: this.#fb.nonNullable.control<string>('', [Validators.required, Validators.maxLength(30)]),
        emailAddress: this.#fb.nonNullable.control<string>('', [Validators.email]),
        mobileNumber: this.#fb.nonNullable.control<string>('', [Validators.required, Validators.maxLength(15)]),
        postcode: this.#fb.nonNullable.control<string>('', [
            Validators.required,
            Validators.maxLength(10),
            UKPostcodeValidator(),
        ]),
        addressLine1: this.#fb.control<string | null>('', [Validators.required, Validators.maxLength(100)]),
        addressLine2: this.#fb.control<string | null>('', [Validators.maxLength(100)]),
        addressLine3: this.#fb.control<string | null>('', [Validators.maxLength(100)]),
        addressLine4: this.#fb.control<string | null>('', [Validators.maxLength(100)]),
        locality: this.#fb.control<string | null>('', [Validators.maxLength(30)]),
        town: this.#fb.control<string | null>('', [Validators.required, Validators.maxLength(30)]),
        county: this.#fb.control<string | null>('', [Validators.maxLength(30)]),
        notes: this.#fb.nonNullable.control<string>('', [Validators.maxLength(1000)]),
        appointmentTypeId: this.#fb.control<number | null>(null, [Validators.required]),
        appointmentDate: this.#fb.nonNullable.control<string>('', [
            Validators.required,
            timeGreaterThanMidnightValidator(),
        ]),
        engineerId: this.#fb.nonNullable.control<number>(0),
        fullAddress: this.#fb.control<string | null>({ value: null, disabled: true }), // this is not posted back, just for visuals
    })

    airQuoteForm: AirQuoteForm = this.#fb.group({
        id: this.#fb.nonNullable.control<string>(''),
        packId: this.#fb.nonNullable.control<number>(0),
    })

    get hasAirQuote(): boolean {
        return !!this.form.controls.airQuote
    }

    postcode$!: Observable<string>
    appointmentTypeId$!: Observable<number | null>
    appointmentDate$!: Observable<Date | null>

    hasPreselectedAppointmentTypeId = signal<boolean>(false)

    appointmentType$ = combineLatest([
        this.preform.controls.appointmentTypeId.valueChanges.pipe(
            startWith(this.preform.controls.appointmentTypeId.value)
        ),
        this.form.controls.appointmentTypeId.valueChanges.pipe(startWith(this.form.controls.appointmentTypeId.value)),
        toObservable(this.config).pipe(startWith(null)),
    ]).pipe(
        map(([preformId, formId, config]) => {
            const formName = config?.forms?.find(x => x.appointmentTypeId === (preformId || formId))?.name || ''
            return formName
        })
    )

    constructor() {
        this.customer$.pipe(takeUntilDestroyed()).subscribe({
            next: (customer: Customer | null) => {
                if (customer) {
                    this.customer.update(() => customer)
                    this.populateFormForExistingCustomer(customer)

                    setTimeout(() => {
                        this.scroll(this.datepicker.nativeElement)
                    }, 250)
                }
            },
        })

        this.postcode$ = this.form.controls.postcode.valueChanges.pipe(
            startWith(this.form.controls.postcode.value),
            debounceTime(500),
            distinctUntilChanged()
        )

        this.appointmentTypeId$ = this.form.controls.appointmentTypeId.valueChanges.pipe(
            startWith(this.form.controls.appointmentTypeId.value),
            debounceTime(500),
            distinctUntilChanged()
        )

        this.appointmentDate$ = this.form.controls.appointmentDate.valueChanges.pipe(
            startWith(this.form.controls.appointmentDate.value),
            debounceTime(50),
            map(value => {
                if (!value) {
                    return null
                }
                return new Date(value)
            }),
            distinctUntilChanged(
                (prev, curr) => (prev?.getDate() === curr?.getDate() && prev?.getMonth() === curr?.getMonth()) || false
            )
        )

        combineLatest([this.postcode$, this.appointmentTypeId$])
            .pipe(
                takeUntilDestroyed(),
                filter(([postcode, appointmentTypeId]) => !!postcode && !!appointmentTypeId)
            )
            .subscribe(([postcode, appointmentTypeId]) =>
                this.bookAppointmentStore.loadAvailableDates({
                    postcode: postcode,
                    appointmentTypeId: appointmentTypeId!,
                })
            )

        this.appointmentTypeId$.pipe(takeUntilDestroyed()).subscribe({
            next: value => {
                if (value === 3 || value === 6) {
                    this.form.controls.notes.addValidators([Validators.required])
                } else {
                    this.form.controls.notes.removeValidators([Validators.required])
                }
                this.form.updateValueAndValidity()
            },
        })

        this.appointmentDate$.pipe(takeUntilDestroyed(), filter(Boolean)).subscribe(date => {
            this.bookAppointmentStore.loadTimes({
                date: dateWithoutTimezone(new Date(date)),
                postcode: this.form.controls.postcode.value,
                appointmentTypeId: this.form.controls.appointmentTypeId.value!,
            })
        })
    }

    parentForm!: FormGroup
    childForm!: FormGroup

    ngOnInit(): void {
        this.#route.queryParamMap.subscribe({
            next: params => {
                this.handleAppointmentType(params.get('appointmenttypeid'))

                this.hasPreselectedAppointmentTypeId.update(() => !!params.get('appointmenttypeid'))

                this.handleAirQuote(params.get('airquoteid'), params.get('packid'))

                if (params.has('appointmenttypeid') && params.has('cguid')) {
                    this.stage.update(() => 'form')
                }
            },
        })
    }

    private handleAirQuote(airQuoteId: string | null, airQuotePackId: string | null): void {
        if (airQuoteId && airQuotePackId) {
            this.airQuoteForm.patchValue({
                id: airQuoteId,
                packId: Number(airQuotePackId),
            })

            this.form.addControl('airQuote', this.airQuoteForm)
        }
    }

    private handleAppointmentType(appointmentTypeId: string | null) {
        const atid = Number(appointmentTypeId)

        if (atid) {
            setTimeout(() => {
                this.preform.patchValue({ appointmentTypeId: atid })
                this.form.patchValue({ appointmentTypeId: atid })
            }, 1000)
        }
    }

    submitPreform(): void {
        if (this.preform.invalid) {
            this.preform.markAllAsTouched()
            return
        }

        this.form.patchValue({
            appointmentTypeId: this.preform.controls.appointmentTypeId.value,
            mobileNumber: this.preform.controls.mobileNumber.value,
            postcode: this.preform.controls.postcode.value,
        })

        this.#customerService
            .checkIfCustomerExists(null, this.preform.controls.mobileNumber.value, this.preform.controls.postcode.value)
            .pipe(
                tap((customer: Customer | null) => {
                    if (customer) {
                        this.bookAppointmentStore.setCustomer(customer)
                    }
                }),
                switchMap((customer: Customer | null) => {
                    if (customer) {
                        // if we have the customer we set the stage for the main form
                        return of([])
                    } else {
                        // otherwise we get the address suggestions and show them to the customer
                        return this.#postcodeService.getSuggestions(this.preform.controls.postcode.value)
                    }
                })
            )
            .subscribe({
                next: addresses => {
                    this.addressSuggestions.update(() => addresses)

                    if (addresses.length === 0) {
                        this.stage.update(() => 'form')
                        this.postcodeError.update(() => 'No addresses found for this postcode')
                    } else {
                        this.postcodeError.update(() => null)
                        setTimeout(() => {
                            this.addressSelector.open()
                        }, 250)
                    }
                },
            })
    }

    isDateEnabled(date: NgbDate): boolean {
        const dateStr = new Date(date.year, date.month - 1, date.day).getTime()
        const isAvailable = this.bookAppointmentStore.enabledDatesAsTime()?.some(x => x === dateStr) || false
        return isAvailable
    }

    isDisabled = (date: NgbDateStruct, current: { year: number; month: number } | undefined) => {
        const dateStr = new Date(date.year, date.month - 1, date.day).getTime()

        const isDisabled = !this.bookAppointmentStore.enabledDatesAsTime()?.some(x => x === dateStr) || false
        return isDisabled
    }

    selectDate(date: NgbDate): void {
        this.selectedTime.update(() => null)
        this.form.patchValue({
            appointmentDate: dateWithoutTimezone(new Date(date.year, date.month - 1, date.day)),
        })

        setTimeout(() => {
            this.scroll(this.timepicker.nativeElement)
        }, 250)
    }

    selectTime(time: BookingEngineAvailableTime): void {
        this.selectedTime.update(() => time)
        this.form.patchValue({
            appointmentDate: time.availableStartTime,
            engineerId: time.engineerId,
        })

        setTimeout(() => {
            this.scroll(this.form.valid ? this.confirmation.nativeElement : this.confirmation.nativeElement)
        }, 250)
    }

    populateFormForExistingCustomer(customer: Customer | null) {
        if (!customer) {
            return
        }

        this.preform.patchValue({
            mobileNumber: customer.mobileNumber,
            postcode: customer.postcode,
        })

        this.preform.controls.mobileNumber.disable()
        this.preform.controls.postcode.disable()

        this.form.patchValue(
            {
                customerApiKey: customer.apiKey,
                postcode: customer.postcode,
                title: customer.title,
                firstName: customer.firstName,
                lastName: customer.lastName,
                mobileNumber: customer.mobileNumber,
                emailAddress: customer.emailAddress,
                addressLine1: customer.addressLine1,
                addressLine2: customer.addressLine2,
                addressLine3: customer.addressLine3,
                addressLine4: customer.addressLine4,
                locality: customer.locality,
                town: customer.town,
                county: customer.county,
            },
            { emitEvent: true }
        )

        this.form.controls.postcode.clearValidators()
        this.form.controls.title.clearValidators()
        this.form.controls.firstName.clearValidators()
        this.form.controls.lastName.clearValidators()
        this.form.controls.mobileNumber.clearValidators()
        this.form.controls.addressLine1.clearValidators()
        this.form.controls.town.clearValidators()
        this.form.updateValueAndValidity()
    }

    applyCustomerValidators(): void {
        this.form.controls.postcode.addValidators([
            Validators.required,
            Validators.maxLength(10),
            UKPostcodeValidator(),
        ])

        this.form.controls.title.addValidators([Validators.required])
        this.form.controls.firstName.addValidators([Validators.required, Validators.maxLength(30)])
        this.form.controls.lastName.addValidators([Validators.required, Validators.maxLength(30)])
        this.form.controls.emailAddress.addValidators([Validators.email])
        this.form.controls.mobileNumber.addValidators([Validators.required, Validators.maxLength(15)])
        this.form.controls.addressLine1.addValidators([Validators.required, Validators.maxLength(100)])
        this.form.controls.town.addValidators([Validators.required, Validators.maxLength(30)])
    }

    setAddress(address: PostcodeSuggestion): void {
        this.#postcodeService.getAddress(address.id).subscribe({
            next: address => {
                this.form.patchValue(
                    {
                        addressLine1: address.line1,
                        addressLine2: address.line2,
                        addressLine3: address.line3,
                        addressLine4: address.line4,
                        locality: address.locality,
                        town: address.townOrCity,
                        county: address.county,
                    },
                    { emitEvent: false }
                )

                this.form.patchValue({
                    fullAddress: address.formattedAddress.filter(Boolean).join(', '),
                })
                this.stage.update(() => 'form')
            },
            error: () => {},
        })
    }

    confirmBooking(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched()
            alert('Please ensure the form is filled out correctly')
            return
        }

        this.#recaptchaService
            .execute('submit_booking_form')
            .pipe(
                switchMap(token => {
                    return this.#bookingEngineService.makeBooking(
                        this.form.getRawValue() as BookingEngineMakeBooking,
                        token
                    )
                }),
                catchError(error => {
                    console.error(error)
                    // Handle the error appropriately
                    return of(null) // Or return an observable with a default value
                })
            )
            .subscribe({
                next: bookingDetails => {
                    // Handle successful booking
                    this.bookingEngineResponse.update(() => bookingDetails)
                    this.scroll(this.top.nativeElement)
                },
                error: () => {
                    // Optionally handle any errors not caught by catchError
                    alert('Something went wrong, please try again or contact us via phone/email.')
                },
                complete: () => {
                    this.#modalService.dismissAll()
                },
            })
    }

    reset(): void {
        this.#router.navigate(['/enquiry/book-appointment'])

        this.preform.reset()
        this.form.reset()
        this.applyCustomerValidators()
        this.stage.update(() => 'preform')
        this.customer.update(() => null)
        this.addressSuggestions.update(() => [])
        this.postcodeError.update(() => null)

        this.form.controls.appointmentTypeId.enable()
        this.preform.controls.appointmentTypeId.enable()

        this.preform.controls.mobileNumber.enable()
        this.preform.controls.postcode.enable()

        this.scroll(this.top.nativeElement)
    }

    scroll(el: HTMLElement): void {
        el.scrollIntoView({ behavior: 'smooth' })
    }
}
interface EnquiryForm
    extends FormGroup<{
        customerApiKey: FormControl<string | null>
        appointmentTypeId: FormControl<number | null>
        appointmentDate: FormControl<string>
        engineerId: FormControl<number>
        title: FormControl<string>
        firstName: FormControl<string>
        lastName: FormControl<string>
        postcode: FormControl<string>
        mobileNumber: FormControl<string>
        addressLine1: FormControl<string | null>
        addressLine2: FormControl<string | null>
        addressLine3: FormControl<string | null>
        addressLine4: FormControl<string | null>
        locality: FormControl<string | null>
        town: FormControl<string | null>
        county: FormControl<string | null>
        emailAddress: FormControl<string>
        notes: FormControl<string>
        airQuote?: AirQuoteForm
        fullAddress: FormControl<string | null>
    }> {}

interface AirQuoteForm
    extends FormGroup<{
        id: FormControl<string>
        packId: FormControl<number>
    }> {}
