import { Component, input } from '@angular/core'

@Component({
    selector: 'ngx-quote-question',
    standalone: true,
    imports: [],
    templateUrl: './quote-question.component.html',
    styleUrl: './quote-question.component.scss',
})
export class QuoteQuestionComponent {
    question = input<string>()
    explanation = input<string>()
}
