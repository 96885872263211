<div>
    <p class="small pb-2 border-1 border-bottom">Price including installation from</p>
</div>
<div class="fw-bold fs-3">{{ price() | currency }}</div>
<div class="small">Including VAT</div>
@if (financeSettings().hasFinance) {
    <div class="small text-subtle">
        or from
        <a
            [routerLink]=""
            style="cursor: pointer; text-decoration: underline"
            class="text-success link-underline-success"
            (click)="toggleFinance()"
            >{{ financeOptions().monthlyCost | currency }}</a
        >
        per month **
    </div>

    @if (showFinance()) {
        <label for="yearRange" class="form-label text-start mb-0 mt-3">Select Years ({{ yearSelector() }} years)</label>
        <input
            type="range"
            class="form-range"
            id="yearRange"
            [(ngModel)]="yearSelector"
            [max]="maxFinancePeriod()"
            [min]="1"
            [step]="1" />

        @if (financeSettings().financeInterestFreePeriod > 0) {
            <p class="small mb-1 fw-bold text-success">
                Interest Free available up to {{ financeSettings().financeInterestFreePeriod }} years
            </p>
        }

        <div class="d-flex justify-content-between small mb-1">
            <div>APR Representative</div>
            <div class="fw-bold" [class.text-success]="financeOptions().apr === 0">
                {{ financeOptions().apr / 100 | percent: '1.2-2' }}
            </div>
        </div>
        <div class="d-flex justify-content-between small mb-1">
            <div>Price</div>
            <div class="fw-bold">{{ price() | currency }}</div>
        </div>
        <div class="d-flex justify-content-between small mb-1">
            <div>Your Deposit ({{ financeSettings().depositPercentage / 100 | percent }})</div>
            <div class="fw-bold">{{ financeOptions().depositAmount | currency }}</div>
        </div>
        <div class="d-flex justify-content-between small mb-1">
            <div>Amount on finance</div>
            <div class="fw-bold">{{ financeOptions().totalOnFinance | currency }}</div>
        </div>
        <div class="d-flex justify-content-between small mb-1">
            <div>Monthly Payment</div>
            <div class="fw-bold">{{ financeOptions().monthlyCost | currency }}</div>
        </div>
        <div class="d-flex justify-content-between small mb-1">
            <div>Loan Duration</div>
            <div class="fw-bold">{{ yearSelector() }} Years</div>
        </div>
        <div class="d-flex justify-content-between small mb-1">
            <div>Total Amount Payable</div>
            <div class="fw-bold">{{ financeOptions().totalAmountWithApr | currency }}</div>
        </div>

        <a class="d-flex justify-content-center small" [routerLink]="[]" (click)="toggleFinance()">close</a>
    }
}
