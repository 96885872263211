import { Component, Input, OnInit, forwardRef } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { LabelComponent } from '../label/label.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'

@Component({
    selector: 'sf-toggle',
    standalone: true,
    imports: [FontAwesomeModule, LabelComponent],
    templateUrl: './toggle.component.html',
    styleUrl: './toggle.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleComponent),
            multi: true,
        },
    ],
})
export class ToggleComponent implements ControlValueAccessor {
    private _checked = false
    public get checked(): boolean {
        return !!this._checked
    }

    @Input() label: string = ''
    @Input() description: string = ''
    @Input() inputId: string = ''
    @Input() activeText: string = 'Enabled'
    @Input() inactiveText: string = 'Disabled'

    @Input() public disabled!: boolean
    @Input()
    public set checked(val: boolean) {
        if (val !== this._checked) {
            this._checked = val
            this.onChange(val)
            this.onTouched()
        }
    }

    private onChange: any = () => {}
    private onTouched: any = () => {}

    public registerOnChange(fn: () => void) {
        this.onChange = fn
    }

    public registerOnTouched(fn: () => void) {
        this.onTouched = fn
    }

    public writeValue(value: boolean) {
        console.log(value)
        this.checked = value
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled
    }

    public toggle() {
        this.checked = !this.checked
    }
}
