import { AbstractControl, ValidatorFn } from '@angular/forms'

export function timeGreaterThanMidnightValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const dateValue = new Date(control.value)

        if (!control.value || isNaN(dateValue.getTime())) {
            // If there's no value or it's not a valid date, don't return an error.
            return null
        }

        // Check if the time is greater than midnight.
        if (dateValue.getHours() === 0 && dateValue.getMinutes() === 0 && dateValue.getSeconds() === 0) {
            return { timeNotGreaterThanMidnight: true }
        }

        return null
    }
}
