import { AsyncPipe, DatePipe, JsonPipe } from '@angular/common'
import { ChangeDetectorRef, Component, OnInit, inject, signal } from '@angular/core'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'
import { FormBuilder, Validators, FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms'
import { ActivatedRoute, Router, RouterLink } from '@angular/router'
import { NgbAlertModule, NgbDate, NgbDateStruct, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap'
import { BookingEngineService } from 'projects/quoting-app/src/app/services/booking-engine.service'
import { ExistingCustomerCheckerComponent } from 'projects/quoting-app/src/app/shared/components/existing-customer-checker/existing-customer-checker.component'
import { ButtonComponent } from 'projects/quoting-app/src/app/shared/components/form/button/button.component'
import { FloatingLabelInputComponent } from 'projects/quoting-app/src/app/shared/components/form/floating-label-input/floating-label-input.component'
import { FloatingLabelPostcodeInputComponent } from 'projects/quoting-app/src/app/shared/components/form/floating-label-postcode-input/floating-label-postcode-input.component'
import { FloatingLabelTextareaInputComponent } from 'projects/quoting-app/src/app/shared/components/form/floating-label-textarea-input/floating-label-textarea-input.component'
import { InputTextareaComponent } from 'projects/quoting-app/src/app/shared/components/form/input-textarea/input-textarea.component'
import { InputComponent } from 'projects/quoting-app/src/app/shared/components/form/input/input.component'
import { LabelComponent } from 'projects/quoting-app/src/app/shared/components/form/label/label.component'
import { PostcodeLookupComponent } from 'projects/quoting-app/src/app/shared/components/postcode-lookup/postcode-lookup.component'
import { BookingEngineAvailableDates } from 'projects/quoting-app/src/app/shared/interfaces/booking-engine/booking-engine-available-dates.interface'
import {
    BookingEngineAvailableTime,
    BookingEngineAvailableTimes,
} from 'projects/quoting-app/src/app/shared/interfaces/booking-engine/booking-engine-available-times.interface'
import { Customer } from 'projects/quoting-app/src/app/shared/interfaces/customer/customer.interface'
import { PostcodeAddress } from 'projects/quoting-app/src/app/shared/interfaces/postcode/postcode-address.interface'
import { timeGreaterThanMidnightValidator } from 'projects/quoting-app/src/app/shared/validators/greater-than-midnight.validator'
import { UKPostcodeValidator } from 'projects/quoting-app/src/app/shared/validators/postcode.validator.validator'
import {
    Observable,
    catchError,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    of,
    startWith,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs'
import { BookingStore } from './booking.store'
import { ConfigService } from 'projects/quoting-app/src/app/services/config.service'
import { NgSelectModule } from '@ng-select/ng-select'
import { dateWithoutTimezone } from 'projects/sf-shared-lib/src/lib/date-without-timezone'
import { CustomerCardComponent } from 'projects/quoting-app/src/app/shared/components/customer-card/customer-card.component'
import { ReCaptchaV3Service, RecaptchaV3Module } from 'ng-recaptcha'
import { BookingEngineResponse } from 'projects/quoting-app/src/app/shared/interfaces/booking-engine/booking-engine-booking-response.interface'
import { BookingEngineMakeBooking } from 'projects/quoting-app/src/app/shared/interfaces/booking-engine/booking-engine-make-booking.interface'

@Component({
    selector: 'sf-booking-enquiry',
    standalone: true,
    imports: [
        RouterLink,
        RecaptchaV3Module,
        ReactiveFormsModule,
        NgSelectModule,
        NgbDatepickerModule,
        NgbAlertModule,
        CustomerCardComponent,
        ButtonComponent,
        LabelComponent,
        InputComponent,
        InputTextareaComponent,
        FloatingLabelInputComponent,
        FloatingLabelTextareaInputComponent,
        FloatingLabelPostcodeInputComponent,
        ExistingCustomerCheckerComponent,
        PostcodeLookupComponent,
        JsonPipe,
        AsyncPipe,
        DatePipe,
    ],
    templateUrl: './booking-enquiry.component.html',
    styleUrl: './booking-enquiry.component.scss',
})
export class BookingEnquiryComponent implements OnInit {
    bookingStore = inject(BookingStore)

    #bookingEngineService = inject(BookingEngineService)
    #configService = inject(ConfigService)
    #fb = inject(FormBuilder)
    #recaptchaService = inject(ReCaptchaV3Service)
    #route = inject(ActivatedRoute)
    #router = inject(Router)

    customer$ = toObservable(this.bookingStore.customer)
    bookings = this.#configService.config

    showAddressControls = signal<boolean>(false)
    timeslotError = signal<boolean>(false)
    bookingDetails = signal<BookingEngineResponse | null>(null)

    //availableDates$!: Observable<BookingEngineAvailableDates | null>
    // availableTimes$!: Observable<BookingEngineAvailableTimes | null>
    minDate = signal<NgbDateStruct>({ year: 2021, month: 1, day: 1 })
    maxDate = signal<NgbDateStruct>({ year: 2021, month: 1, day: 2 })
    //enabledDates: string[] = []
    availableStartTimes: BookingEngineAvailableTime[] = []
    hoveredDate: NgbDate | null = null
    loadingTimeSlots = signal<boolean>(false)

    showCustomerSeachBox = signal<boolean>(true)

    form!: EnquiryForm

    postcode$!: Observable<string>
    appointmentTypeId$!: Observable<number | null>

    constructor() {
        this.form = this.#fb.group({
            customerApiKey: this.#fb.control<string | null>(null),
            firstName: this.#fb.nonNullable.control<string>('', [Validators.required, Validators.maxLength(30)]),
            lastName: this.#fb.nonNullable.control<string>('', [Validators.required, Validators.maxLength(30)]),
            emailAddress: this.#fb.nonNullable.control<string>('', [Validators.required, Validators.email]),
            mobileNumber: this.#fb.nonNullable.control<string>('', [Validators.required, Validators.maxLength(15)]),
            postcode: this.#fb.nonNullable.control<string>('', [
                Validators.required,
                Validators.maxLength(10),
                UKPostcodeValidator(),
            ]),
            addressLine1: this.#fb.control<string | null>('', [Validators.required, Validators.maxLength(100)]),
            addressLine2: this.#fb.control<string | null>('', [Validators.maxLength(100)]),
            addressLine3: this.#fb.control<string | null>('', [Validators.maxLength(100)]),
            addressLine4: this.#fb.control<string | null>('', [Validators.maxLength(100)]),
            locality: this.#fb.control<string | null>('', [Validators.maxLength(30)]),
            town: this.#fb.control<string | null>('', [Validators.required, Validators.maxLength(30)]),
            county: this.#fb.control<string | null>('', [Validators.required, Validators.maxLength(30)]),
            notes: this.#fb.nonNullable.control<string>('', [Validators.maxLength(1000)]),
            appointmentTypeId: this.#fb.control<number | null>(null, [Validators.required]),
            appointmentDate: this.#fb.nonNullable.control<string>('', [
                Validators.required,
                timeGreaterThanMidnightValidator(),
            ]),
            engineerId: this.#fb.nonNullable.control<number>(0),
        })

        this.postcode$ = this.form.controls.postcode.valueChanges.pipe(
            startWith(this.form.controls.postcode.value),
            debounceTime(500),
            distinctUntilChanged()
        )
        this.appointmentTypeId$ = this.form.controls.appointmentTypeId.valueChanges.pipe(
            startWith(this.form.controls.appointmentTypeId.value),
            debounceTime(500),
            distinctUntilChanged()
        )

        // this.bookingStore.loadDates(combineLatest([this.postcode$, this.appointmentTypeId$]));
        combineLatest([this.postcode$, this.appointmentTypeId$])
            .pipe(
                takeUntilDestroyed(),
                filter(([postcode, appointmentTypeId]) => !!postcode && !!appointmentTypeId)
            )
            .subscribe(([postcode, appointmentTypeId]) =>
                this.bookingStore.loadDates({ postcode: postcode, appointmentTypeId: appointmentTypeId! })
            )

        this.form.controls.appointmentDate.valueChanges
            .pipe(
                takeUntilDestroyed(),
                map(value => {
                    if (!value) {
                        return null
                    }
                    return new Date(value)
                }),
                distinctUntilChanged(
                    (prev, curr) =>
                        (prev?.getDate() === curr?.getDate() && prev?.getMonth() === curr?.getMonth()) || false
                ),
                filter(Boolean)
            )
            .subscribe(date => {
                this.bookingStore.loadTimes({
                    date: dateWithoutTimezone(new Date(date)),
                    postcode: this.form.controls.postcode.value,
                    appointmentTypeId: this.form.controls.appointmentTypeId.value!,
                })
            })

        this.customer$.pipe(takeUntilDestroyed()).subscribe(customer => {
            this.populateFormForExistingCustomer(customer)
        })
    }

    ngOnInit(): void {
        if (this.bookingStore.appointmentTypeId()) {
            const exists = this.#configService
                .config()
                ?.forms.some(x => x.appointmentTypeId === this.bookingStore.appointmentTypeId())

            const defaultAppointmentTypeId = 2 //service

            this.form.controls.appointmentTypeId.patchValue(
                exists ? this.bookingStore.appointmentTypeId() : defaultAppointmentTypeId
            )
        }
    }

    resetForm(): void {
        this.form.reset()
        this.bookingStore.setCustomer(null)
        this.showAddressControls.update(() => false)
        this.showCustomerSeachBox.update(() => true)
        this.#router.navigate([], {
            relativeTo: this.#route,
        })
        this.enableFormControls()
    }

    removeCustomer(): void {
        const appointmentTypeId = this.form.controls.appointmentTypeId.value

        this.bookingStore.resetCustomer()
        this.resetForm()

        this.form.patchValue({
            appointmentTypeId: appointmentTypeId,
        })

        this.enableFormControls()
    }

    enableFormControls(): void {
        this.form.controls.firstName.enable()
        this.form.controls.lastName.enable()
        // this.form.controls.postcode.enable()
        this.form.controls.mobileNumber.enable()
        this.form.controls.emailAddress.enable()
        this.form.controls.addressLine1.enable()
        this.form.controls.addressLine2.enable()
        this.form.controls.addressLine3.enable()
        this.form.controls.addressLine4.enable()
        this.form.controls.locality.enable()
        this.form.controls.town.enable()
        this.form.controls.county.enable()
    }

    populateFormForExistingCustomer(customer: Customer | null) {
        if (!customer) {
            return
        }

        this.showCustomerSeachBox.update(() => false)
        this.bookingStore.setCustomer(customer)

        this.form.patchValue(
            {
                customerApiKey: customer.apiKey,
                postcode: customer.postcode,
                firstName: customer.firstName,
                lastName: customer.lastName,
                mobileNumber: customer.mobileNumber,
                emailAddress: customer.emailAddress,
                addressLine1: customer.addressLine1,
                addressLine2: customer.addressLine2,
                addressLine3: customer.addressLine3,
                addressLine4: customer.addressLine4,
                locality: customer.locality,
                town: customer.town,
                county: customer.county,
            },
            { emitEvent: true }
        )

        this.form.controls.firstName.disable()
        this.form.controls.lastName.disable()
        // this.form.controls.postcode.disable()
        this.form.controls.mobileNumber.disable()
        this.form.controls.emailAddress.disable()
        this.form.controls.addressLine1.disable()
        this.form.controls.addressLine2.disable()
        this.form.controls.addressLine3.disable()
        this.form.controls.addressLine4.disable()
        this.form.controls.locality.disable()
        this.form.controls.town.disable()
        this.form.controls.county.disable()

        this.showAddressControls.update(() => true)
    }

    setAddress(address: PostcodeAddress): void {
        this.form.patchValue(
            {
                addressLine1: address.line1,
                addressLine2: address.line2,
                addressLine3: address.line3,
                addressLine4: address.line4,
                locality: address.locality,
                town: address.townOrCity,
                county: address.county,
            },
            { emitEvent: false }
        )

        this.showAddressControls.update(() => true)
    }

    setManualAddress(value: boolean): void {
        this.showAddressControls.update(() => value)
    }

    isDateEnabled(date: NgbDate): boolean {
        const dateStr = new Date(date.year, date.month - 1, date.day).getTime()
        const isAvailable = this.bookingStore.enabledDatesAsTime()?.some(x => x === dateStr) || false
        return isAvailable
    }

    isDisabled = (date: NgbDateStruct, current: { year: number; month: number } | undefined) => {
        const dateStr = new Date(date.year, date.month - 1, date.day).getTime()

        const isDisabled = !this.bookingStore.enabledDatesAsTime()?.some(x => x === dateStr) || false
        return isDisabled
    }

    selectDate(date: NgbDate): void {
        this.form.patchValue({
            appointmentDate: dateWithoutTimezone(new Date(date.year, date.month - 1, date.day)),
        })
    }

    selectTime(time: BookingEngineAvailableTime): void {
        this.form.patchValue({
            appointmentDate: time.availableStartTime,
            engineerId: time.engineerId,
        })
    }

    submit(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched()
            return
        }

        this.#recaptchaService
            .execute('submit_booking_form')
            .pipe(
                switchMap(token => {
                    return this.#bookingEngineService.makeBooking(
                        this.form.getRawValue() as BookingEngineMakeBooking,
                        token
                    )
                }),
                catchError(error => {
                    console.error(error)
                    // Handle the error appropriately
                    return of(null) // Or return an observable with a default value
                })
            )
            .subscribe({
                next: bookingDetails => {
                    // Handle successful booking
                    this.bookingDetails.update(() => bookingDetails)
                },
                error: () => {
                    // Optionally handle any errors not caught by catchError
                },
            })
    }
}

interface EnquiryForm
    extends FormGroup<{
        customerApiKey: FormControl<string | null>
        appointmentTypeId: FormControl<number | null>
        appointmentDate: FormControl<string>
        engineerId: FormControl<number>
        firstName: FormControl<string>
        lastName: FormControl<string>
        postcode: FormControl<string>
        mobileNumber: FormControl<string>
        addressLine1: FormControl<string | null>
        addressLine2: FormControl<string | null>
        addressLine3: FormControl<string | null>
        addressLine4: FormControl<string | null>
        locality: FormControl<string | null>
        town: FormControl<string | null>
        county: FormControl<string | null>
        emailAddress: FormControl<string>
        notes: FormControl<string>
    }> {}
