import { computed, inject } from '@angular/core'
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import { tapResponse } from '@ngrx/operators'
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals'
import { rxMethod } from '@ngrx/signals/rxjs-interop'
import { BookingEngineService } from 'projects/quoting-app/src/app/services/booking-engine.service'
import { CustomerService } from 'projects/quoting-app/src/app/services/customer.service'
import { BookingEngineAvailableDates } from 'projects/quoting-app/src/app/shared/interfaces/booking-engine/booking-engine-available-dates.interface'
import { BookingEngineAvailableTimes } from 'projects/quoting-app/src/app/shared/interfaces/booking-engine/booking-engine-available-times.interface'
import { Customer } from 'projects/quoting-app/src/app/shared/interfaces/customer/customer.interface'
import {
    setPending,
    setFulfilled,
    withRequestStatus,
    setError,
} from 'projects/quoting-app/src/app/shared/stores/shared/call-state.feature'
import { withQueryParams } from 'projects/quoting-app/src/app/shared/stores/shared/query-params.feature'
import { pipe, switchMap, of, debounceTime, distinctUntilChanged, tap } from 'rxjs'

interface BookAppointmentState {
    customer: Customer | null
    availableDates: BookingEngineAvailableDates | null
    availableTimes: BookingEngineAvailableTimes | null
}

const initialState: BookAppointmentState = {
    customer: null,
    availableDates: null,
    availableTimes: null,
}

export const BookAppointmentStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withComputed(store => ({
        enabledDates: computed(() => store.availableDates()?.dateEnabledDates),
        enabledDatesAsTime: computed(
            () => store.availableDates()?.dateEnabledDates.map(x => new Date(x).getTime()) || []
        ),
        minDate: computed(() => {
            const startDate = store.availableDates()?.dateStartDate

            if (startDate) {
                const date = new Date(startDate)
                return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() } as NgbDateStruct
            }

            return { year: 2021, month: 1, day: 1 } as NgbDateStruct
        }),
        maxDate: computed(() => {
            const endDate = store.availableDates()?.dateEndDate

            if (endDate) {
                const date = new Date(endDate)
                return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() } as NgbDateStruct
            }

            return { year: 2021, month: 1, day: 2 } as NgbDateStruct
        }),
    })),
    withRequestStatus(),
    withQueryParams({ cguid: param => String(param) || '', appointmentTypeId: param => Number(param) }),
    withMethods(
        (store, customerService = inject(CustomerService), bookingEngineService = inject(BookingEngineService)) => {
            return {
                loadAvailableDates: rxMethod<{ postcode: string; appointmentTypeId: number }>(
                    pipe(
                        tap(() => {
                            patchState(store, { availableDates: null, availableTimes: null })
                        }),
                        distinctUntilChanged(),
                        debounceTime(250),
                        switchMap(({ postcode, appointmentTypeId }) => {
                            patchState(store, setPending())
                            return bookingEngineService.getDates({ postcode, appointmentTypeId }).pipe(
                                tapResponse({
                                    next: dates => {
                                        patchState(store, { availableDates: dates })
                                    },
                                    error: error => {
                                        patchState(store, { availableDates: null }, setError('Unable to find dates'))
                                    },
                                    finalize: () => {
                                        patchState(store, setFulfilled())
                                    },
                                })
                            )
                        })
                    )
                ),
                loadTimes: rxMethod<{ date: string; postcode: string; appointmentTypeId: number }>(
                    pipe(
                        distinctUntilChanged(),
                        switchMap(({ date, postcode, appointmentTypeId }) => {
                            patchState(store, setPending())

                            return bookingEngineService
                                .getTimes({
                                    appointmentDate: date,
                                    postcode: postcode,
                                    appointmentTypeId: appointmentTypeId,
                                })
                                .pipe(
                                    tapResponse({
                                        next: times => {
                                            patchState(store, { availableTimes: times })
                                        },
                                        error: error => console.log,
                                        finalize: () => {
                                            patchState(store, setFulfilled())
                                        },
                                    })
                                )
                        })
                    )
                ),
                setCustomer(customer: Customer | null): void {
                    patchState(store, { customer })
                },
                loadCustomer: rxMethod<string>(
                    pipe(
                        switchMap(guid => {
                            patchState(store, setPending())
                            return !!guid
                                ? customerService.getCustomerByGuid(guid).pipe(
                                      tapResponse({
                                          next: customer => {
                                              patchState(store, { customer })
                                          },
                                          error: error => {
                                              console.log
                                          },
                                          finalize: () => {
                                              patchState(store, setFulfilled())
                                          },
                                      })
                                  )
                                : of(null)
                        })
                    )
                ),
            }
        }
    ),
    withHooks({
        onInit({ loadCustomer, cguid }) {
            if (cguid() !== 'undefined') {
                loadCustomer(cguid)
            }
        },
    })
)
