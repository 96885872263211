import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { environment } from '../../environments/environment'
import { PostcodeSuggestion } from '../shared/interfaces/postcode/postcode-suggestion.interface'
import { Observable, map } from 'rxjs'
import { PostcodeAddress } from '../shared/interfaces/postcode/postcode-address.interface'
import { ApiResponse } from 'projects/sf-quoting-lib/src/lib/interfaces/api-response.interface'

@Injectable({
    providedIn: 'root',
})
export class PostcodeService {
    #httpClient = inject(HttpClient)

    getSuggestions(postcode: string): Observable<PostcodeSuggestion[]> {
        return this.#httpClient
            .get<ApiResponse<PostcodeSuggestion[]>>(`${environment.baseUrl}postcode?postcode=${postcode}`)
            .pipe(map(response => response.result))
    }

    getAddress(id: string): Observable<PostcodeAddress> {
        return this.#httpClient
            .get<ApiResponse<PostcodeAddress>>(`${environment.baseUrl}postcode/address/${id}`)
            .pipe(map(response => response.result))
    }
}
