import { Component, inject } from '@angular/core'
import { ConfigService } from '../../services/config.service'
import { JsonPipe } from '@angular/common'
import { RouterOutlet } from '@angular/router'
import { CompanyHeaderComponent } from '../../layout/company-header/company-header.component'
import { CompanyFooterComponent } from '../../layout/company-footer/company-footer.component'

@Component({
    selector: 'sf-home',
    standalone: true,
    imports: [RouterOutlet, CompanyHeaderComponent, CompanyFooterComponent, JsonPipe],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
})
export class HomeComponent {
    #configService = inject(ConfigService)

    config = this.#configService.config
}
