import { Component, inject, signal } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms'
import { InputComponent } from '../../../../shared/components/form/input/input.component'
import { JsonPipe } from '@angular/common'
import { ButtonComponent } from '../../../../shared/components/form/button/button.component'
import { CtaMobileAppVerticalComponent } from 'projects/quoting-app/src/app/shared/components/cta-mobile-app-vertical/cta-mobile-app-vertical.component'
import { InputTextareaComponent } from 'projects/quoting-app/src/app/shared/components/form/input-textarea/input-textarea.component'
import { UtilityService } from 'projects/quoting-app/src/app/services/utility.service'
import { UKPostcodeValidator } from 'projects/quoting-app/src/app/shared/validators/postcode.validator.validator'
import { MakeEnquiry } from 'projects/quoting-app/src/app/shared/interfaces/make-enquiry.interface'
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap'
import { InputPostcodeComponent } from 'projects/quoting-app/src/app/shared/components/form/input-postcode/input-postcode.component'
import { ReCaptchaV3Service, RecaptchaV3Module } from 'ng-recaptcha'
import { catchError, of, switchMap } from 'rxjs'

@Component({
    selector: 'sf-general-enquiry',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgbAlertModule,
        RecaptchaV3Module,
        ButtonComponent,
        InputComponent,
        InputPostcodeComponent,
        InputTextareaComponent,
        CtaMobileAppVerticalComponent,
        JsonPipe,
    ],
    templateUrl: './general-enquiry.component.html',
    styleUrl: './general-enquiry.component.scss',
})
export class GeneralEnquiryComponent {
    #fb = inject(FormBuilder)
    #recaptchaService = inject(ReCaptchaV3Service)
    #utilityService = inject(UtilityService)

    submitted = signal<boolean>(false)
    error = signal<boolean>(false)

    form: EnquiryForm = this.#fb.group({
        firstName: this.#fb.nonNullable.control<string>('', [Validators.required, Validators.maxLength(30)]),
        lastName: this.#fb.nonNullable.control<string>('', [Validators.required, Validators.maxLength(30)]),
        postcode: this.#fb.nonNullable.control<string>('', [Validators.required, UKPostcodeValidator()]),
        emailAddress: this.#fb.control<string>('', [Validators.email]),
        phone: this.#fb.nonNullable.control<string>('', [Validators.required, Validators.maxLength(15)]),
        message: this.#fb.nonNullable.control<string>('', [Validators.required, Validators.maxLength(1000)]),
    })

    submit(): void {
        this.form.markAllAsTouched()
        if (this.form.invalid) {
            return
        }

        this.#recaptchaService
            .execute('submit_enquiry_form')
            .pipe(
                switchMap(token => {
                    return this.#utilityService.makeEnquiry(this.form.value as MakeEnquiry)
                }),
                catchError(error => {
                    console.error(error)
                    this.error.set(true)
                    // Handle the error appropriately
                    return of(null) // Or return an observable with a default value
                })
            )
            .subscribe({
                next: () => {
                    this.submitted.set(true)
                },
                error: () => {
                    this.error.set(true)
                },
            })
    }
}

interface EnquiryForm
    extends FormGroup<{
        firstName: FormControl<string>
        lastName: FormControl<string>
        postcode: FormControl<string>
        emailAddress: FormControl<string | null>
        phone: FormControl<string>
        message: FormControl<string>
    }> {}
