import { Component, Input } from '@angular/core'
import { ButtonComponent } from '../../shared/components/form/button/button.component'
import { RouterLink } from '@angular/router'
import { UtilitySettings } from '../../shared/interfaces/config/utility-settings.interface'

@Component({
    selector: 'header[sf-company-header]',
    standalone: true,
    imports: [RouterLink, ButtonComponent],
    templateUrl: './company-header.component.html',
    styleUrl: './company-header.component.scss',
})
export class CompanyHeaderComponent {
    @Input() companyName = ''

    @Input() settings!: UtilitySettings
}
