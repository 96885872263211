import { Component } from '@angular/core';

@Component({
  selector: 'sf-not-found',
  standalone: true,
  imports: [],
  template: `<div class="container my-4">
    <div class="row">
      <div class="col-12 text-center">
        <h1>404 - Not Found</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
      </div>
    </div>
  </div>`,
})
export class NotFoundComponent {}
