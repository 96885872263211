import { Component, HostBinding, Input, input } from '@angular/core'
import { NgxMaskPipe } from 'ngx-mask'

@Component({
    selector: 'sf-header',
    standalone: true,
    imports: [NgxMaskPipe],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
    host: {
        class: 'd-flex bg-brand-primary text-white p-3 align-items-center',
    },
})
export class HeaderComponent {
    @Input() headerText: string = ''
    @Input() phoneNumber: string | undefined = ''
    @Input() logo: string | undefined = ''
}
