import { Component, inject } from '@angular/core'
import { RouterLink } from '@angular/router'
import { ConfigService } from 'projects/quoting-app/src/app/services/config.service'
import { ButtonComponent } from 'projects/quoting-app/src/app/shared/components/form/button/button.component'
import { UtilityCardComponent } from '../../components/utility-card/utility-card.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { CtaMobileAppHorizontalComponent } from 'projects/quoting-app/src/app/shared/components/cta-mobile-app-horizontal/cta-mobile-app-horizontal.component'

@Component({
    selector: 'sf-landing-shell',
    standalone: true,
    imports: [RouterLink, CtaMobileAppHorizontalComponent, FontAwesomeModule, ButtonComponent, UtilityCardComponent],
    templateUrl: './landing-shell.component.html',
    styleUrl: './landing-shell.component.scss',
})
export class LandingShellComponent {
    #config = inject(ConfigService)

    config = this.#config.config
}
