import { JsonPipe } from '@angular/common'
import { Component, EventEmitter, Output, inject, input, signal } from '@angular/core'
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { NgbActiveModal, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap'
import { AirQuoteService } from 'projects/quoting-app/src/app/services/air-quote.service'
import { InputComponent } from 'projects/quoting-app/src/app/shared/components/form/input/input.component'
import { FloatingLabelInputComponent } from 'projects/quoting-app/src/app/shared/components/form/floating-label-input/floating-label-input.component'
import { TakeCustomerDetailsFirstForm } from './take-customer-details-first-form.interface'
import { AirQuoteCustomer } from 'projects/quoting-app/src/app/shared/interfaces/air-quote-customer.interface'
import { ToggleComponent } from 'projects/quoting-app/src/app/shared/components/form/toggle/toggle.component'
import { FloatingLabelPostcodeInputComponent } from 'projects/quoting-app/src/app/shared/components/form/floating-label-postcode-input/floating-label-postcode-input.component'

@Component({
    selector: 'sf-request-callback-modal',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgbAlertModule,
        InputComponent,
        ToggleComponent,
        FloatingLabelInputComponent,
        FloatingLabelPostcodeInputComponent,
        JsonPipe,
    ],
    templateUrl: './take-customer-details-first-modal.component.html',
    styleUrl: './take-customer-details-first-modal.component.scss',
})
export class TakeCustomerDetailsFirstModalComponent {
    activeModal = inject(NgbActiveModal)
    #fb = inject(FormBuilder)
    #airQuoteService = inject(AirQuoteService)

    error = signal<string | null>(null)

    quoteId = input.required<string>()

    @Output()
    takenCustomerDetailsCompleted = new EventEmitter<void>()

    form = this.#fb.group<TakeCustomerDetailsFirstForm>({
        firstName: this.#fb.nonNullable.control<string>('', [Validators.required, Validators.maxLength(50)]),
        lastName: this.#fb.nonNullable.control<string>('', [Validators.required, Validators.maxLength(50)]),
        postcode: this.#fb.nonNullable.control<string>('', [Validators.required, Validators.maxLength(10)]),
        phone: this.#fb.nonNullable.control<string>('', [Validators.required, Validators.maxLength(15)]),
        emailAddress: this.#fb.nonNullable.control<string>('', [Validators.required, Validators.email]),
        confirmed: this.#fb.nonNullable.control<boolean>(false, [Validators.requiredTrue]),
    })

    submit(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched()
            return
        }

        this.#airQuoteService.takeCustomerDetails(this.quoteId(), this.form.value as AirQuoteCustomer).subscribe({
            next: () => {
                this.takenCustomerDetailsCompleted.emit()
                this.activeModal.close(this.form.value)
            },
            error: () => {
                this.error.update(() => 'An error occurred while submitting the request. Please try again.')
            },
        })
    }
}
