import { patchState, signalStore, type, withComputed, withHooks, withMethods, withState } from '@ngrx/signals'
import { removeAllEntities, setAllEntities, setEntities, withEntities } from '@ngrx/signals/entities'
import { withRouteParams } from '../../shared/stores/shared/route-params.feature'
import { AirQuoteSettings } from '../../shared/interfaces/air-quote-settings.interface'
import { AirQuoteService } from '../../services/air-quote.service'
import { DestroyRef, computed, inject } from '@angular/core'
import { rxMethod } from '@ngrx/signals/rxjs-interop'
import { pipe, switchMap } from 'rxjs'
import { tapResponse } from '@ngrx/operators'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { AirQuoteQuestion } from '../../shared/interfaces/air-quote-question.interface'
import { AirQuoteAnswerSubmission } from '../../shared/interfaces/air-quote-answer-submission.interface'
import { AirQuoteBundle } from '../../shared/interfaces/bundles/air-quote-bundle.interface'
import { AirQuoteBundleItem } from '../../shared/interfaces/bundles/air-quote-bundle-item.interface'
import { AirQuoteCompany } from '../../shared/interfaces/air-quote-company.interface'
import { setError, setFulfilled, setPending, withRequestStatus } from '../../shared/stores/shared/call-state.feature'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { RequestCallbackModalComponent } from './components/request-callback-modal/request-callback-modal.component'
import { AirQuoteCallback } from '../../shared/interfaces/callback-requests/air-quote-callback.interface'
import { AirQuoteDetails } from '../../shared/interfaces/air-quote-details.interface'
import { AirQuoteAppointment } from '../../shared/interfaces/air-quote-appointment.interface'
import { QuoteAnswer } from 'projects/sf-quoting-lib/src/lib/components/quote-answers/quote-answer.interface'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { AirQuoteCustomer } from '../../shared/interfaces/air-quote-customer.interface'
import { TakeCustomerDetailsFirstModalComponent } from './components/take-customer-details-first-modal/take-customer-details-first-modal.component'

type AirQuoteAnswer = { questionId: number; answerId: number }

interface AirQuoteState {
    quoteId: string | null
    details: AirQuoteDetails | null
    settings: AirQuoteSettings | null
    company: AirQuoteCompany | null
    appointment: AirQuoteAppointment | null
    customer: AirQuoteCustomer | null
    customerApiKey: string | null
    questionIndex: number
    selectedBundleId: number | null
}

const initialState: AirQuoteState = {
    quoteId: null,
    details: null,
    settings: null,
    company: null,
    appointment: null,
    customer: null,
    customerApiKey: null,
    questionIndex: 0,
    selectedBundleId: null,
}

export const AirQuoteStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withRouteParams({
        key: param => String(param),
        id: param => String(param),
    }),
    withRequestStatus(),
    withEntities({ entity: type<AirQuoteQuestion>(), collection: 'questions' }),
    withEntities({ entity: type<AirQuoteAnswer>(), collection: 'answers' }),
    withEntities({ entity: type<AirQuoteBundleItem>(), collection: 'bundles' }),
    withComputed(store => ({
        hideRequestButtons: computed(() => {
            return !!store.appointment() || !!store.details()?.hasRequestedCallback
        }),
        selectedBundle: computed(() => {
            const bundleId = store.selectedBundleId
            return store.bundlesEntities().find(x => x.id === bundleId())
        }),
        currentQuestion: computed(() => {
            const questionId = store.questionIndex
            return store.questionsEntities()[questionId()]
        }),
        questionProgress: computed(() => {
            if (store.bundlesEntities().length) {
                return 1
            }
            return (store.questionIndex() + 1) / store.questionsEntities().length
        }),
        isFirstQuestion: computed(() => {
            return store.questionIndex() === 0
        }),
        isLastQuestion: computed(() => {
            return store.questionIndex() === store.questionsEntities().length - 1
        }),
        answersForQuestion: computed(() => {
            return store.questionsEntities()[store.questionIndex()].answers.map(x => {
                return {
                    id: x.id,
                    answer: x.answer,
                    explanation: x.explanation,
                    imageUrl: x.imageUrl,
                } as QuoteAnswer
            })
        }),
    })),
    withMethods(
        (
            store,
            activatedRoute = inject(ActivatedRoute),
            router = inject(Router),
            airQuoteService = inject(AirQuoteService),
            modalService = inject(NgbModal),
            destroyRef = inject(DestroyRef)
        ) => {
            return {
                openRequestSurveyModal(packId: number, surveyType: 'home' | 'virtual'): void {
                    modalService.dismissAll()

                    let params: Params = {
                        appointmenttypeid: surveyType === 'home' ? 23 : 20,
                        airquoteid: store.quoteId(),
                        packid: packId,
                    }

                    if (store.customerApiKey()) {
                        params.cguid = store.customerApiKey()
                    }

                    if (store.customer()) {
                        params.cguid = store.customer()?.apiKey
                    }

                    router.navigate(['enquiry', 'book-appointment'], { queryParams: params })
                },
                openRequestCallbackModal(packId: number): void {
                    modalService.dismissAll()

                    const modalRef = modalService.open(RequestCallbackModalComponent, {
                        centered: true,
                        size: 'lg',
                        fullscreen: 'sm',
                        scrollable: true,
                    })
                    modalRef.componentInstance.quoteId = store.quoteId
                    modalRef.componentInstance.selectedPackId = packId
                    modalRef.componentInstance.customer = store.customer()
                    modalRef.componentInstance.callbackRequestCompleted.pipe(takeUntilDestroyed(destroyRef)).subscribe({
                        next: () => {
                            router.navigate(['air-quote', store.quoteId(), 'bundles'])
                            this.loadExistingQuote(store.quoteId()!)
                        },
                    })
                },
                openTakeCustomerDetailsFirstModal(): void {
                    const modalRef = modalService.open(TakeCustomerDetailsFirstModalComponent, {
                        centered: true,
                        size: 'lg',
                        fullscreen: 'sm',
                        scrollable: true,
                    })
                    modalRef.componentInstance.quoteId = store.quoteId
                    modalRef.componentInstance.takenCustomerDetailsCompleted
                        .pipe(takeUntilDestroyed(destroyRef))
                        .subscribe({
                            next: () => {
                                this.submitAnswers({ answers: store.answersEntities() })
                                this.loadExistingQuote(store.quoteId()!)
                            },
                        })
                },
                submitCallbackRequest(request: AirQuoteCallback): void {
                    patchState(store, setPending())

                    airQuoteService.submitCallbackRequest(store.quoteId()!, request).subscribe({
                        next: () => {
                            patchState(store, setFulfilled())
                        },
                        error: error => {
                            patchState(store, setError('Error submitting callback request'))
                        },
                    })
                },
                selectBundle(id: number): void {
                    patchState(store, { selectedBundleId: id })
                    router.navigate(['/air-quote', store.quoteId(), 'bundle', id])
                },
                selectAnswer(answer: QuoteAnswer): void {
                    const answerObj: AirQuoteAnswer = { questionId: store.currentQuestion().id, answerId: answer.id }
                    patchState(store, setEntities([answerObj], { collection: 'answers', idKey: 'questionId' }))

                    this.goToNextQuestion()
                },
                goToNextQuestion(): void {
                    if (store.isLastQuestion()) {
                        if (store.settings()?.takeCustomerDetailsFirst && !store.customer()) {
                            this.openTakeCustomerDetailsFirstModal()
                            return
                        }

                        this.submitAnswers({ answers: store.answersEntities() })
                        return
                    }

                    patchState(store, { questionIndex: store.questionIndex() + 1 })
                },
                goToPreviousQuestion: () => {
                    if (store.questionIndex() === 0) return
                    patchState(store, { questionIndex: store.questionIndex() - 1 })
                },
                restartQuote(): void {
                    patchState(
                        store,
                        { questionIndex: 0 },
                        removeAllEntities({ collection: 'answers' }),
                        removeAllEntities({ collection: 'bundles' })
                    )

                    this.initializeAirQuote()
                },
                submitAnswers: rxMethod<AirQuoteAnswerSubmission>(
                    pipe(
                        switchMap(answers => {
                            patchState(store, setPending())

                            return airQuoteService.submitAnswers(store.quoteId()!, answers).pipe(
                                tapResponse({
                                    next: (response: AirQuoteBundle) => {
                                        patchState(store, setAllEntities(response.items, { collection: 'bundles' }))
                                        patchState(store, setFulfilled())
                                        router.navigate(['air-quote', store.quoteId(), 'bundles'])
                                    },
                                    error: error => {
                                        patchState(store, setError('Error initializing quote'))
                                        router.navigate(['/error'])
                                    },
                                })
                            )
                        })
                    )
                ),
                loadExistingQuote: rxMethod<string>(
                    switchMap(id => {
                        patchState(store, setPending())

                        return airQuoteService.getExistingQuoteById(id).pipe(
                            tapResponse({
                                next: response => {
                                    const { primaryColour, secondaryColour } = response.settings
                                    document.documentElement.style.setProperty('--brand-primary', primaryColour)
                                    document.documentElement.style.setProperty('--brand-secondary', secondaryColour)

                                    patchState(store, setAllEntities(response.questions, { collection: 'questions' }))

                                    patchState(
                                        store,
                                        {
                                            quoteId: response.id,
                                            details: response.details,
                                            settings: response.settings,
                                            company: response.company,
                                            appointment: response.appointment,
                                            customer: response.customer,
                                        },

                                        setFulfilled()
                                    )
                                    if (response.bundle?.items.length) {
                                        patchState(
                                            store,
                                            setAllEntities(response.bundle.items, { collection: 'bundles' })
                                        )
                                        router.navigate(['/air-quote', response.id, 'bundles'])
                                    }
                                },
                                error: error => {
                                    patchState(store, setError('Error initializing quote'))
                                    router.navigate(['/error'])
                                },
                            })
                        )
                    })
                ),
                initializeAirQuote: rxMethod<void>(
                    switchMap(() => {
                        patchState(store, setPending())

                        return airQuoteService.initialize().pipe(
                            tapResponse({
                                next: response => {
                                    const { primaryColour, secondaryColour } = response.settings
                                    document.documentElement.style.setProperty('--brand-primary', primaryColour)
                                    document.documentElement.style.setProperty('--brand-secondary', secondaryColour)

                                    patchState(
                                        store,
                                        {
                                            quoteId: response.id,
                                            details: response.details,
                                            settings: response.settings,
                                            company: response.company,
                                        },
                                        setFulfilled()
                                    )
                                    if (response.bundle?.items.length) {
                                        patchState(
                                            store,
                                            setAllEntities(response.bundle.items, { collection: 'bundles' })
                                        )
                                    }

                                    router.navigate(['/air-quote', response.id])
                                },
                                error: error => {
                                    patchState(store, setError('Error initializing quote'))
                                    router.navigate(['/error'])
                                },
                            })
                        )
                    })
                ),
            }
        }
    ),
    withHooks({
        onInit: ({ key, initializeAirQuote }) => {
            //   console.log('apikey', key());
            //   if (key() !== undefined && key() !== 'undefined') {
            //     initializeAirQuote(key)
            //   }
        },
    })
)
