import { Injectable } from '@angular/core'
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomDateAdapter extends NgbDateAdapter<string> {
    fromModel(value: string | null): NgbDateStruct | null {
        if (value) {
            const dateParts = value.split('T')[0].split('-')
            if (dateParts.length === 3) {
                return {
                    year: parseInt(dateParts[0], 10),
                    month: parseInt(dateParts[1], 10),
                    day: parseInt(dateParts[2], 10),
                }
            }
        }
        return null
    }

    toModel(date: NgbDateStruct | null): string | null {
        if (date) {
            const day = date.day.toString().padStart(2, '0')
            const month = date.month.toString().padStart(2, '0')
            const year = date.year.toString()
            return `${year}-${month}-${day}`
        }
        return null
    }
}
