import { Routes } from '@angular/router'
import { AirQuoteWizardComponent } from './modules/air-quote/pages/air-quote-wizard/air-quote-wizard.component'
import { NotFoundComponent } from './modules/not-found/not-found.component'
import { AirQuoteBundlesComponent } from './modules/air-quote/pages/air-quote-bundles/air-quote-bundles.component'
import { ErrorComponent } from './modules/error/error.component'
import { AirQuoteShellComponent } from './modules/air-quote/pages/air-quote-shell/air-quote-shell.component'
import { AirQuoteBundleComponent } from './modules/air-quote/pages/air-quote-bundle/air-quote-bundle.component'
import { authChildGuard, authGuard } from './shared/guards/auth.guard'
import { HomeComponent } from './modules/home/home.component'
import { GeneralEnquiryComponent } from './modules/enquiry/pages/general-enquiry/general-enquiry.component'
import { EnquiryShellComponent } from './modules/enquiry/pages/enquiry-shell/enquiry-shell.component'
import { BookingEnquiryComponent } from './modules/enquiry/pages/booking-enquiry/booking-enquiry.component'
import { LandingShellComponent } from './modules/landing/pages/landing-shell/landing-shell.component'
import { BookAppointmentComponent } from './modules/enquiry/pages/book-appointment/book-appointment.component'
import { StripeSuccessComponent } from './modules/payments/pages/stripe-success/stripe-success.component'

export const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate: [authGuard],
        canActivateChild: [authChildGuard],
        children: [
            {
                path: '',
                component: LandingShellComponent,
            },
            {
                path: 'payment/stripe/success',
                component: StripeSuccessComponent,
            },
            {
                path: 'enquiry',
                component: EnquiryShellComponent,
                children: [
                    {
                        path: '',
                        redirectTo: 'general',
                        pathMatch: 'full',
                    },
                    {
                        path: 'general',
                        component: GeneralEnquiryComponent,
                    },
                    {
                        path: 'booking',
                        component: BookingEnquiryComponent,
                    },
                    {
                        path: 'book-appointment',
                        component: BookAppointmentComponent,
                    },
                ],
            },
        ],
    },
    {
        path: 'air-quote',
        redirectTo: 'air-quote/0',
    },
    {
        path: 'air-quote/:id',
        component: AirQuoteShellComponent,
        children: [
            {
                path: '',
                component: AirQuoteWizardComponent,
                pathMatch: 'full',
            },
            {
                path: 'bundles',
                component: AirQuoteBundlesComponent,
            },
            {
                path: 'bundle/:bundleId',
                component: AirQuoteBundleComponent,
            },
        ],
    },
    {
        path: 'error',
        component: ErrorComponent,
    },
    {
        path: 'not-found',
        component: NotFoundComponent,
    },
    { path: '**', redirectTo: 'not-found' },
]
