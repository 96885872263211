<div class="row g-2">
    <div [ngClass]="{ '': enterManually(), 'col-6 col-md-9': !enterManually() }">
        <sf-label [label]="label" [for]="inputId" [required]="isRequired"></sf-label>
        <input
            class="form-control mb-0"
            [class.form-control-sm]="size == 'sm'"
            [class.form-control-lg]="size == 'lg'"
            [required]="isRequired"
            [id]="inputId"
            [formControl]="control"
            autocomplete="none"
            [placeholder]="placeholder"
            toUppercase />
    </div>
    <div class="align-content-end" [ngClass]="{ 'd-none': enterManually(), 'col-6 col-md-3': !enterManually() }">
        <div class="d-grid">
            <button
                sfButton
                type="button"
                size="md"
                color="secondary"
                style="height: 50px"
                [disabled]="!control.valid"
                (click)="searchPostcode()">
                Search
            </button>
        </div>
    </div>
    @if (showManualInputButton && postcodeSuggestions.length == 0) {
        <div class="col-12">
            <button sfButton size="sm" color="dark" variant="outline" (click)="toggleManualInput()">
                Manual Input
            </button>
        </div>
    }
    <div class="col-12">
        @if (control.touched && control.dirty && control.errors) {
            @for (err of control.errors | keyvalue; track err) {
                <div class="small text-danger">
                    {{ errorMessages[err.key] }}
                </div>
            }
        }
    </div>
</div>

@if (suggestionError()) {
    <div class="row">
        <div class="col-12">
            <p class="text-danger">{{ suggestionError() }}</p>
        </div>
    </div>
}

@if (postcodeSuggestions.length) {
    <div class="row">
        <div class="col-12">
            <div class="mb-3">
                <label for="postcode-lookup" class="form-label">Select an Address</label>
                <select
                    class="form-select"
                    [class.form-control-sm]="size == 'sm'"
                    [class.form-control-lg]="size == 'lg'"
                    id="postcode-lookup"
                    (change)="onAddressSelected($any($event.target)?.value)">
                    <option selected>Open this select menu</option>
                    @for (item of postcodeSuggestions; track item) {
                        <option [value]="item.id">{{ item.address }}</option>
                    }
                </select>
            </div>
        </div>
    </div>
}
