import { AfterViewInit, Component, ElementRef, Input, OnInit, forwardRef, viewChild } from '@angular/core'
import { ControlContainer, FormControl, NG_VALUE_ACCESSOR, NgControl, ReactiveFormsModule } from '@angular/forms'
import { LabelComponent } from '../label/label.component'
import { ControlValueAccessorDirective } from '../../../directives/control-value-accessor.directive'
import { KeyValuePipe } from '@angular/common'

@Component({
    selector: 'sf-floating-label-input',
    standalone: true,
    imports: [ReactiveFormsModule, LabelComponent, KeyValuePipe],
    templateUrl: './floating-label-input.component.html',
    styleUrl: './floating-label-input.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FloatingLabelInputComponent),
            multi: true,
        },
    ],
})
export class FloatingLabelInputComponent<T> extends ControlValueAccessorDirective<T> implements OnInit, AfterViewInit {
    @Input() inputId = ''
    @Input() label = ''
    @Input() customErrorMessages: Record<string, string> = {}
    @Input() placeholder = ''
    @Input() description = ''
    @Input() size: 'sm' | 'md' | 'lg' = 'md'
    @Input() isReadOnly = false

    @Input() autofocus = false

    inputEl = viewChild<ElementRef>('input')

    errorMessages: Record<string, string> = {
        required: 'This field is required',
        invalidHexColor: 'Must be a valid colour',
        invalidPostcode: 'Must be a valid postcode',
    }

    override ngOnInit(): void {
        this.errorMessages = { ...this.errorMessages, ...this.customErrorMessages }
        super.ngOnInit()
    }

    ngAfterViewInit(): void {
        if (this.autofocus) {
            this.inputEl()?.nativeElement.focus()
        }
    }
}
