import { AfterViewInit, Component, EventEmitter, Input, Output, forwardRef, inject, signal } from '@angular/core'
import { ControlValueAccessorDirective } from '../../directives/control-value-accessor.directive'
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms'
import { PostcodeService } from '../../../services/postcode.service'
import { PostcodeSuggestion } from '../../interfaces/postcode/postcode-suggestion.interface'
import { PostcodeAddress } from '../../interfaces/postcode/postcode-address.interface'
import { ButtonComponent } from '../form/button/button.component'
import { AsyncPipe, JsonPipe, KeyValuePipe, NgClass } from '@angular/common'
import { LabelComponent } from '../form/label/label.component'
import { UpperCaseInputDirective } from '../../directives/to-uppercase.directive'
import { RouterLink } from '@angular/router'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Observable, debounceTime, map } from 'rxjs'

@Component({
    selector: 'sf-postcode-lookup',
    standalone: true,
    imports: [
        NgClass,
        RouterLink,
        ReactiveFormsModule,
        ButtonComponent,
        LabelComponent,
        KeyValuePipe,
        UpperCaseInputDirective,
        forwardRef(() => ControlValueAccessorDirective),
        AsyncPipe,
        JsonPipe,
    ],
    templateUrl: './postcode-lookup.component.html',
    styleUrl: './postcode-lookup.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PostcodeLookupComponent),
            multi: true,
        },
    ],
})
export class PostcodeLookupComponent<T> extends ControlValueAccessorDirective<T> {
    private postcodeLookupService = inject(PostcodeService)

    @Input() inputId = ''
    @Input() label = ''
    @Input() placeholder = ''
    @Input() size: 'sm' | 'md' | 'lg' = 'md'

    errorMessages: Record<string, string> = {
        required: 'This field is required',
        maxlength: 'This field has too many characters',
        minlength: 'This field has too many characters',
        invalidPostcode: 'Please enter a valid postcode',
    }

    postcodeSuggestions: PostcodeSuggestion[] = []
    suggestionError = signal<string | null>(null)
    selectedPostcodeId = ''
    enterManually = signal<boolean>(false)

    @Input()
    showManualInputButton = true

    @Output()
    onAddressChange = new EventEmitter<PostcodeAddress>()

    @Output()
    onManualInput = new EventEmitter<boolean>()

    toggleManualInput(): void {
        this.enterManually.update(value => !value)
        this.onManualInput.emit(this.enterManually())
    }

    searchPostcode() {
        const postcode = this.control.value

        if (postcode && postcode.length >= 5) {
            this.postcodeLookupService.getSuggestions(postcode).subscribe({
                next: suggestions => {
                    this.suggestionError.update(() => null)

                    this.postcodeSuggestions = suggestions

                    if (!suggestions.length) {
                        this.suggestionError.update(
                            () => 'No addresses found for this postcode. Please enter manually.'
                        )
                    }
                },
                error: () => {
                    this.suggestionError.update(
                        () => 'An error occurred while searching for addresses. Please try again.'
                    )
                },
            })
        }
    }

    onAddressSelected(id: string) {
        this.postcodeLookupService.getAddress(id).subscribe(address => {
            this.onAddressChange.emit(address)
        })

        this.postcodeSuggestions = []
    }
}
