<div class="quote-answers">
    @for (answer of answers(); track answer.id) {
        <div
            class="quote-answers__list-item"
            [class.quote-answers__list-item--explanation]="!!answer.explanation"
            role="button"
            (click)="selectAnswer.emit(answer)">
            <div class="quote-answers__list-item-inner">
                <img [src]="answer.imageUrl" />
                <span class="quote-answers__list-item-answer">{{ answer.answer }}</span>
            </div>
            @if (answer.explanation) {
                <span class="quote-answers__list-item-explanation">{{ answer.explanation }}</span>
            }
            <span class="quote-answers__list-item-select">Select</span>
        </div>
    }
</div>
