import { HttpClient } from '@angular/common/http'
import { Injectable, inject, signal } from '@angular/core'
import { BehaviorSubject, Observable, map, tap } from 'rxjs'
import { AirQuoteInitialization } from '../shared/interfaces/air-quote-initialization.interface'
import { environment } from '../../environments/environment'
import { AirQuoteAnswerSubmission } from '../shared/interfaces/air-quote-answer-submission.interface'
import { AirQuoteBundle } from '../shared/interfaces/bundles/air-quote-bundle.interface'
import { AirQuoteCallback } from '../shared/interfaces/callback-requests/air-quote-callback.interface'
import { AirQuoteVirtualSurvey } from '../shared/interfaces/callback-requests/air-quote-virtual-survey.interface'
import { AirQuoteHomeSurvey } from '../shared/interfaces/callback-requests/air-quote-home-survey.interface'
import { ApiResponse } from 'projects/sf-quoting-lib/src/lib/interfaces/api-response.interface'
import { AirQuoteCustomerDetails } from '../shared/interfaces/callback-requests/air-quote-customer-details.interface'

@Injectable({
    providedIn: 'root',
})
export class AirQuoteService {
    #httpClient = inject(HttpClient)

    #storageKeyName = 'COMPANY_API_KEY'

    set companyApiKey(value: string) {
        localStorage.setItem(this.#storageKeyName, value)
    }

    get companyApiKey(): string {
        return localStorage.getItem(this.#storageKeyName) || ''
    }

    initialize(): Observable<AirQuoteInitialization> {
        return this.#httpClient
            .post<ApiResponse<AirQuoteInitialization>>(`${environment.baseUrl}consumer/air-quotes/initialize`, {})
            .pipe(map(response => response.result))
    }

    getExistingQuoteById(id: string): Observable<AirQuoteInitialization> {
        return this.#httpClient
            .get<ApiResponse<AirQuoteInitialization>>(`${environment.baseUrl}consumer/air-quotes/${id}`)
            .pipe(
                tap(response => (this.companyApiKey = response.result.company.apiKey)),
                map(response => response.result)
            )
    }

    submitAnswers(id: string, answers: AirQuoteAnswerSubmission): Observable<AirQuoteBundle> {
        return this.#httpClient
            .post<ApiResponse<AirQuoteBundle>>(`${environment.baseUrl}consumer/air-quotes/${id}/answers`, answers)
            .pipe(map(response => response.result))
    }

    takeCustomerDetails(id: string, details: AirQuoteCustomerDetails): Observable<string> {
        return this.#httpClient
            .post<ApiResponse<string>>(`${environment.baseUrl}consumer/air-quotes/${id}/customer-details`, details)
            .pipe(map(x => x.result))
    }

    submitCallbackRequest(id: string, request: AirQuoteCallback): Observable<void> {
        return this.#httpClient.post<void>(`${environment.baseUrl}consumer/air-quotes/${id}/callback`, request)
    }
}
