import { Component, input, output } from '@angular/core'
import { QuoteAnswer } from './quote-answer.interface'

@Component({
    selector: 'ngx-quote-answers',
    standalone: true,
    imports: [],
    templateUrl: './quote-answers.component.html',
    styleUrl: './quote-answers.component.scss',
})
export class QuoteAnswersComponent {
    answers = input.required<QuoteAnswer[]>()
    selectAnswer = output<QuoteAnswer>()
}
