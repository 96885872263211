import { Component, HostBinding, Input } from '@angular/core'
import { BootstrapColor } from '../../../types/bootstrap-color.type'

@Component({
    selector: 'button[sfButton], a[sfButton]',
    standalone: true,
    imports: [],
    template: `<span><ng-content></ng-content></span>`,
    styleUrl: './button.component.scss',
    host: {
        class: 'btn',
    },
})
export class ButtonComponent {
    @HostBinding('class')
    get buttonClass() {
        return `btn-${this.variant === 'outline' ? 'outline-' : ''}${this.color} btn-${this.size} ${this.active ? ' active' : ''}`
    }

    @Input()
    color: BootstrapColor = 'primary'

    @Input()
    variant: 'outline' | 'solid' = 'solid'

    @Input()
    size: 'sm' | 'md' | 'lg' = 'md'

    @Input()
    active = false
}
