import { Component, Input, OnInit, forwardRef } from '@angular/core'
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms'
import { LabelComponent } from '../label/label.component'
import { ControlValueAccessorDirective } from '../../../directives/control-value-accessor.directive'
import { KeyValuePipe, UpperCasePipe } from '@angular/common'

@Component({
    selector: 'sf-input-postcode',
    standalone: true,
    imports: [ReactiveFormsModule, LabelComponent, KeyValuePipe, UpperCasePipe],
    templateUrl: './input-postcode.component.html',
    styleUrl: './input-postcode.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputPostcodeComponent),
            multi: true,
        },
    ],
})
export class InputPostcodeComponent<T> extends ControlValueAccessorDirective<T> implements OnInit {
    @Input() inputId = ''
    @Input() label = ''
    @Input() customErrorMessages: Record<string, string> = {}
    @Input() placeholder = ''
    @Input() description = ''
    @Input() size: 'sm' | 'md' | 'lg' = 'md'

    errorMessages: Record<string, string> = {
        required: 'This field is required',
        invalidPostcode: 'Must be a valid postcode',
    }

    override ngOnInit(): void {
        this.errorMessages = { ...this.errorMessages, ...this.customErrorMessages }
        super.ngOnInit()
    }
}
