import { Component, output } from '@angular/core'

@Component({
    selector: 'ngx-quote-previous-button',
    standalone: true,
    imports: [],
    templateUrl: './quote-previous-button.component.html',
    styleUrl: './quote-previous-button.component.scss',
    host: {
        role: 'button',
        '(click)': 'previous.emit()',
    },
})
export class QuotePreviousButtonComponent {
    previous = output()
}
