import { Component, Input, TemplateRef, inject } from '@angular/core'
import { AirQuoteStore } from '../../air-quote.store'
import { JsonPipe } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'
import { PriceConfiguratorComponent } from '../../components/price-configurator/price-configurator.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { NgbAccordionModule, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { RouterLink } from '@angular/router'

@Component({
    selector: 'sf-air-quote-bundle',
    standalone: true,
    imports: [
        RouterLink,
        NgbAccordionModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        PriceConfiguratorComponent,
        JsonPipe,
    ],
    templateUrl: './air-quote-bundle.component.html',
    styleUrl: './air-quote-bundle.component.scss',
})
export class AirQuoteBundleComponent {
    #modalService = inject(NgbModal)

    airQuoteStore = inject(AirQuoteStore)

    @Input()
    set bundleId(bundleId: number) {
        if (!bundleId) return

        this.airQuoteStore.selectBundle(Number(bundleId))
    }

    open(content: TemplateRef<any>) {
        this.#modalService.open(content, { size: 'md', fullscreen: 'sm' })
    }
}
