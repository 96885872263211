@if (airQuoteStore.isFulfilled()) {
    <sf-header
        headerText="Airquote"
        [logo]="airQuoteStore.settings()?.companyLogo"
        [phoneNumber]="airQuoteStore.company()?.phoneNumber"></sf-header>
    <sf-progress [progress]="airQuoteStore.questionProgress() * 100"></sf-progress>
    <router-outlet></router-outlet>
    <div class="container">
        <div class="row">
            <div class="col-12 offset-md-2 col-md-8">
                @if (airQuoteStore.settings()?.companyInformation) {
                    <p class="small my-4 text-center">* {{ airQuoteStore.settings()?.companyInformation }}</p>
                }
                @if (airQuoteStore.settings()?.financeInformation) {
                    <p class="small my-4 text-center">** {{ airQuoteStore.settings()?.financeInformation }}</p>
                }
            </div>
        </div>
    </div>
}
