<div class="mb-2">
    <sf-label [label]="label" [for]="inputId" [required]="isRequired"></sf-label>
    <input
        class="form-control"
        [class.form-control-sm]="size == 'sm'"
        [class.form-control-lg]="size == 'lg'"
        [required]="isRequired"
        [id]="inputId"
        [formControl]="control"
        autocomplete="none"
        [placeholder]="placeholder"
        toUppercase />
    @if (control.touched && control.dirty && control.errors) {
        @for (err of control.errors | keyvalue; track err) {
            <div class="invalid-feedback">
                {{ errorMessages[err.key] }}
            </div>
        }
    }
</div>
