<div class="pack-card d-flex flex-lg-row flex-column mb-4 border border-2 border-light-subtle rounded-3">
    <div class="pack-card__image align-content-top">
        <img
            class="manufacturer-logo"
            src="img-fluid"
            src="{{ pack().manufacturerImageUrl }}"
            alt="Manufacturer Logo" />
        <img class="img-fluid" src="{{ pack().imageUrl }}" alt="Image for {{ pack().name }}" />
    </div>
    <div class="pack-card__content flex-grow-1 p-4">
        <h4>{{ pack().name }}</h4>
        <p class="mb-1 fw-bold">{{ pack().description }}</p>
        <p class="mb-3">{{ pack().longDescription }}</p>

        <div class="d-flex flex-row flex-wrap gap-1 my-2">
            @if (pack().warrantyYears) {
                <span class="fs-6 badge badge-lg bg-info">{{ pack().warrantyYears }} year warranty</span>
            }
            @if (financeSettings().hasFinance && financeSettings().financeInterestFreePeriod > 0) {
                <span class="fs-6 badge badge-lg bg-info">0% Finance</span>
            }
        </div>

        @for (include of pack().includes; track include; let first = $first) {
            <div [class.border-top]="!first" class="d-flex flex-row align-items-center gap-2 small border-1">
                <div class="py-2 flex-shrink-1">
                    <fa-icon class="text-success" [icon]="['fas', 'check-circle']"></fa-icon>
                </div>
                <div class="py-2 flex-grow-1">{{ include.description }}</div>
            </div>
        }
    </div>
    <div class="pack-card__price flex-shrink-1 p-4 text-center border-start border-2">
        <sf-price-configurator [financeSettings]="financeSettings()" [price]="pack().price"></sf-price-configurator>

        <div class="d-grid gap-2 mt-3">
            <button type="button" class="btn btn-lg btn-outline-success" (click)="selectedBundle.emit(pack().id)">
                Choose
            </button>
        </div>
    </div>
</div>
