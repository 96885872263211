import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable, map } from 'rxjs'
import { BookingEngineAvailableDates } from '../shared/interfaces/booking-engine/booking-engine-available-dates.interface'
import { environment } from '../../environments/environment'
import { BookingEngineDatesRequest } from '../shared/interfaces/booking-engine/booking-engine-dates-request.interface'
import { BookingEngineTimesRequest } from '../shared/interfaces/booking-engine/booking-engine-time-request.interface'
import { BookingEngineAvailableTimes } from '../shared/interfaces/booking-engine/booking-engine-available-times.interface'
import { ApiResponse } from 'projects/sf-quoting-lib/src/lib/interfaces/api-response.interface'
import { BookingEngineResponse } from '../shared/interfaces/booking-engine/booking-engine-booking-response.interface'
import { BookingEngineMakeBooking } from '../shared/interfaces/booking-engine/booking-engine-make-booking.interface'

@Injectable({
    providedIn: 'root',
})
export class BookingEngineService {
    #httpClient = inject(HttpClient)

    getDates(request: BookingEngineDatesRequest): Observable<BookingEngineAvailableDates> {
        let params = new HttpParams()
        params = params.append('postcode', request.postcode)
        params = params.append('appointmentTypeId', request.appointmentTypeId.toString())
        params = params.append('skipGlobalLoading', true)

        return this.#httpClient
            .get<ApiResponse<BookingEngineAvailableDates>>(`${environment.baseUrl}booking-engine/dates`, { params })
            .pipe(map(response => response.result))
    }

    getTimes(request: BookingEngineTimesRequest): Observable<BookingEngineAvailableTimes> {
        let params = new HttpParams()
        params = params.append('postcode', request.postcode)
        params = params.append('appointmentTypeId', request.appointmentTypeId.toString())
        params = params.append('appointmentDate', request.appointmentDate)
        params = params.append('skipGlobalLoading', true)

        return this.#httpClient
            .get<ApiResponse<BookingEngineAvailableTimes>>(`${environment.baseUrl}booking-engine/times`, { params })
            .pipe(map(response => response.result))
    }

    makeBooking(request: BookingEngineMakeBooking, recaptchaResponse: string): Observable<BookingEngineResponse> {
        return this.#httpClient
            .post<ApiResponse<BookingEngineResponse>>(`${environment.baseUrl}booking-engine`, request, {
                headers: { 'X-Recaptcha-Response': recaptchaResponse },
            })
            .pipe(map(response => response.result))
    }
}
