import { ChangeDetectionStrategy, Component, input } from '@angular/core'
import { Customer } from '../../interfaces/customer/customer.interface'
import { JsonPipe } from '@angular/common'

@Component({
    selector: 'sf-customer-card',
    standalone: true,
    imports: [JsonPipe],
    templateUrl: './customer-card.component.html',
    styleUrl: './customer-card.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerCardComponent {
    customer = input.required<Customer>()
}
