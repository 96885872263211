import { Component, TemplateRef, inject, output, signal } from '@angular/core'
import { CustomerService } from '../../../services/customer.service'
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms'
import { FloatingLabelInputComponent } from '../form/floating-label-input/floating-label-input.component'
import { ButtonComponent } from '../form/button/button.component'
import { NgbActiveModal, NgbAlertModule, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { Customer } from '../../interfaces/customer/customer.interface'
import { FloatingLabelPostcodeInputComponent } from '../form/floating-label-postcode-input/floating-label-postcode-input.component'
import { UKPostcodeValidator } from '../../validators/postcode.validator.validator'

@Component({
    selector: 'sf-existing-customer-checker',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgbAlertModule,
        FloatingLabelInputComponent,
        FloatingLabelPostcodeInputComponent,
        ButtonComponent,
    ],
    templateUrl: './existing-customer-checker.component.html',
    styleUrl: './existing-customer-checker.component.scss',
})
export class ExistingCustomerCheckerComponent {
    #customerService = inject(CustomerService)
    #fb = inject(FormBuilder)
    #modalService = inject(NgbModal)

    modal: NgbModalRef | null = null

    form: FormGroup = this.#fb.nonNullable.group({
        email: this.#fb.control('', [Validators.required, Validators.email]),
        postcode: this.#fb.control('', [Validators.required, Validators.maxLength(10), UKPostcodeValidator()]),
    })

    customerFound = output<Customer | null>()

    message = signal<string | null>(null)

    open(content: TemplateRef<any>): void {
        this.modal = this.#modalService.open(content, { size: 'md' })
    }

    check(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched()
            return
        }

        this.#customerService
            .checkIfCustomerExists(this.form.controls.email.value, null, this.form.controls.postcode.value)
            .subscribe((customer: Customer | null) => {
                if (customer) {
                    this.customerFound.emit(customer)
                    this.form.reset()
                    this.modal?.close()
                    // this.activeModal.close()
                } else {
                    this.message.update(() => 'Customer not found. Please try again.')
                }
            })
    }
}
