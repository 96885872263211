<section class="page">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="text-success mb-5">Payment Success</h1>
                <p class="fw-bold fs-4">Thank you for your payment.</p>
                <p>You can close this page</p>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>
        </div>
    </div>
</section>
