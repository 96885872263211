@if (config(); as config) {
    <div class="container company-intro">
        <div class="row align-items-center justify-content-lg-start justify-content-center">
            <div class="col-12 col-lg-5 col-xxl-4">
                <div class="d-flex flex-column">
                    <h1 class="mb-4">{{ config.utilitySettings.companyName }}</h1>

                    <div [innerHTML]="config.utilitySettings.information"></div>

                    @if (config.utilitySettings.phoneNumber) {
                        <p class="d-flex align-items-center">
                            <fa-icon class="me-3" [icon]="['fas', 'square-phone']" size="2xl"></fa-icon>
                            <span
                                ><a href="tel:{{ config.utilitySettings.phoneNumber }}">{{
                                    config.utilitySettings.phoneNumber
                                }}</a></span
                            >
                        </p>
                    }
                    @if (config.utilitySettings.emailAddress) {
                        <p class="d-flex align-items-center">
                            <fa-icon class="me-3" [icon]="['fas', 'square-envelope']" size="2xl"></fa-icon>
                            <span
                                ><a href="mailto:{{ config.utilitySettings.emailAddress }}">{{
                                    config.utilitySettings.emailAddress
                                }}</a></span
                            >
                        </p>
                    }
                    @if (config.utilitySettings.websiteUrl) {
                        <p class="d-flex align-items-center">
                            <fa-icon class="me-3" [icon]="['fas', 'earth-europe']" size="2xl"></fa-icon>
                            <span
                                ><a href="{{ config.utilitySettings.websiteUrl }}" target="_blank">{{
                                    config.utilitySettings.websiteUrl
                                }}</a></span
                            >
                        </p>
                    }
                </div>
            </div>
            <div class="col-12 col-lg-7 offset-xxl-1 col-xxl-7 mt-5 mt-lg-0">
                <div class="row gx-2">
                    <div class="col-12 mb-2">
                        <sf-utility-card
                            name="Book Appointment"
                            subtitle="Book an appointment in seconds"
                            prefix="far"
                            icon="calendar-check">
                            <p>
                                Book a service, repair or something else and we'll get back to you as soon as possible.
                            </p>
                            <a sfButton color="primary" [routerLink]="['enquiry', 'book-appointment']">Book now</a>
                        </sf-utility-card>
                    </div>

                    @if (config.hasAirQuote) {
                        <div class="col-12 mb-2">
                            <sf-utility-card
                                name="AirQuote"
                                subtitle="A customized Air Conditioning estimate"
                                prefix="fas"
                                icon="list-check">
                                <p>
                                    Answer a few quick questions to get an online estimate in seconds, including
                                    installation options and product details
                                </p>
                                <a sfButton color="primary" [routerLink]="['air-quote']">Get a quote today</a>
                            </sf-utility-card>
                        </div>
                    }

                    <div class="col-12 mb-2">
                        <sf-utility-card
                            name="General Enquiry"
                            subtitle="For anything else"
                            prefix="fas"
                            icon="envelope-circle-check">
                            <p>We're here to help. Send us a message and we'll get back to you as soon as possible.</p>
                            <a sfButton color="primary" [routerLink]="['enquiry', 'general']">Have a query?</a>
                        </sf-utility-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

<sf-cta-mobile-app-horizontal></sf-cta-mobile-app-horizontal>
<div class="container">
    <div class="row">
        <div class="col-md-12"></div>
        <div class="col-md-4"></div>
    </div>
</div>

<!-- <section class="page-section page-section--enquiry">
    <div class="container">
        <div class="row align-items-center g-5">
            <div class="col-lg-6">
                <img src="/assets/images/air-con-shot.png" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <h1 class="mb-3">Book an appointment</h1>
                <p class="fs-5 mb-5">
                    Book a service, repair or something else and we'll get back to you as soon as possible.
                </p>
                <div class="d-grid">
                    <a sfButton color="primary" size="lg" [routerLink]="['enquiry', 'booking']">Book now</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="page-section page-section--general-enquiry">
    <div class="container">
        <div class="row align-items-center g-5">
            <div class="col-lg-6">
                <h1 class="mb-3">Have a question?</h1>
                <p class="fs-5 mb-5">
                    We're here to help. Send us a message and we'll get back to you as soon as possible.
                </p>
                <div class="d-grid">
                    <a sfButton color="primary" size="lg" [routerLink]="['enquiry', 'general']">Ask a question</a>
                </div>
            </div>
            <div class="col-lg-6">
                <img src="/assets/images/air-con-shot.png" class="img-fluid" />
            </div>
        </div>
    </div>
</section>

<section class="page-section page-section--air-con">
    <div class="container">
        <div class="row align-items-center g-5">
            <div class="col-lg-6">
                <img src="/assets/images/air-con-shot.png" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <h1 class="mb-3">Free online Air Con quote</h1>
                <p class="fs-5 mb-5">
                    Answer a few quick questions to get an online estimate in seconds, including installation options
                    and product details
                </p>
                <div class="d-grid">
                    <a sfButton color="primary" size="lg" [routerLink]="['air-quote']">Get a quote today</a>
                </div>
            </div>
        </div>
    </div>
</section> -->
