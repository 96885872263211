<div>
    <h2 class="mb-0">{{ headerText }}</h2>
</div>

@if (logo) {
    <div class="header__logo shadow">
        <img src="{{ logo }}" alt="Logo" />
    </div>
}
@if (phoneNumber) {
    <a href="tel:{{ phoneNumber }}" target="_blank" class="ms-auto call-us-link d-none d-md-block">
        <div class="header__call-us shadow bg-light text-dark rounded-3 p-2 flex flex-column">
            <div class="header__call-us-text small fw-light">Questions? Call us:</div>
            <div class="header__call-us-number fw-bold fs-5">{{ phoneNumber | mask: '00000 000000' }}</div>
        </div>
    </a>
}
