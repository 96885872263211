import { CurrencyPipe, JsonPipe } from '@angular/common'
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { AirQuoteBundleItem } from 'projects/quoting-app/src/app/shared/interfaces/bundles/air-quote-bundle-item.interface'
import { PriceConfiguratorComponent } from '../price-configurator/price-configurator.component'
import { AirQuoteSettings } from 'projects/quoting-app/src/app/shared/interfaces/air-quote-settings.interface'

@Component({
    selector: 'sf-air-quote-pack-card',
    standalone: true,
    imports: [FontAwesomeModule, PriceConfiguratorComponent, CurrencyPipe, JsonPipe],
    templateUrl: './air-quote-pack-card.component.html',
    styleUrl: './air-quote-pack-card.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: '',
    },
})
export class AirQuotePackCardComponent {
    financeSettings = input.required<AirQuoteSettings>()
    pack = input.required<AirQuoteBundleItem>()
    hideRequestButtons = input<boolean>(false)

    selectedBundle = output<number>()

    requestHomeSurvey = output<AirQuoteBundleItem>()
    requestVirtualSurvey = output<AirQuoteBundleItem>()
    requestCallback = output<AirQuoteBundleItem>()
}
