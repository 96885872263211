import { Component, Input, OnInit, forwardRef } from '@angular/core'
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms'
import { LabelComponent } from '../label/label.component'
import { ControlValueAccessorDirective } from '../../../directives/control-value-accessor.directive'
import { KeyValuePipe } from '@angular/common'

@Component({
    selector: 'sf-input',
    standalone: true,
    imports: [ReactiveFormsModule, LabelComponent, KeyValuePipe],
    templateUrl: './input.component.html',
    styleUrl: './input.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
})
export class InputComponent<T> extends ControlValueAccessorDirective<T> implements OnInit {
    @Input() inputId = ''
    @Input() label = ''
    @Input() customErrorMessages: Record<string, string> = {}
    @Input() placeholder = ''
    @Input() description = ''
    @Input() size: 'sm' | 'md' | 'lg' = 'md'

    errorMessages: Record<string, string> = {
        required: 'This field is required',
        invalidHexColor: 'Must be a valid colour',
    }

    override ngOnInit(): void {
        this.errorMessages = { ...this.errorMessages, ...this.customErrorMessages }
        super.ngOnInit()
    }
}
