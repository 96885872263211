import { JsonPipe, KeyValuePipe } from '@angular/common'
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { LabelComponent } from 'projects/quoting-app/src/app/shared/components/form/label/label.component'
import { ControlValueAccessorDirective } from 'projects/quoting-app/src/app/shared/directives/control-value-accessor.directive'
import { PortalBookingEngineOption } from 'projects/quoting-app/src/app/shared/interfaces/config/portal-booking-engine-option.interface'

@Component({
    selector: 'sf-appointment-radio-selector',
    standalone: true,
    imports: [LabelComponent, KeyValuePipe, JsonPipe],
    templateUrl: './appointment-radio-selector.component.html',
    styleUrl: './appointment-radio-selector.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AppointmentRadioSelectorComponent),
            multi: true,
        },
    ],
})
export class AppointmentRadioSelectorComponent extends ControlValueAccessorDirective<number> {
    @Input() options: PortalBookingEngineOption[] = []

    @Output() selectionChanged = new EventEmitter<PortalBookingEngineOption>()

    selectedOption: PortalBookingEngineOption | null = null

    errorMessages: Record<string, string> = {
        required: 'Please select an appointment type',
    }

    valueChanged(option: PortalBookingEngineOption) {
        this.selectedOption = option

        this.writeValue(option.appointmentTypeId)
        this.selectionChanged.emit(option)
    }

    override writeValue(value: number): void {
        this.selectedOption = this.options.find(option => option.appointmentTypeId === value) || null
        super.writeValue(value)
    }
}
