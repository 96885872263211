import { HttpClient } from '@angular/common/http'
import { Injectable, inject, signal } from '@angular/core'
import { map, tap } from 'rxjs'
import { PortalConfig } from '../shared/interfaces/config/portal-config.interface'
import { environment } from '../../environments/environment'
import { ApiResponse } from 'projects/sf-quoting-lib/src/lib/interfaces/api-response.interface'
import { Title } from '@angular/platform-browser'

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    #title = inject(Title)
    #httpClient = inject(HttpClient)

    #apiKey = signal<string | null>(null)
    apiKey = this.#apiKey.asReadonly()

    #config = signal<PortalConfig | null>(null)
    config = this.#config.asReadonly()

    initialize(subdomain: string) {
        return this.#httpClient
            .get<
                ApiResponse<PortalConfig>
            >(`${environment.baseUrl}consumer-utilities/initialize?subdomain=${subdomain}`)
            .pipe(
                map(response => response.result),
                tap((config: PortalConfig) => {
                    this.#apiKey.set(config.apiKey)
                    this.#config.set(config)

                    this.#title.setTitle(config.utilitySettings.companyName || 'Surefire Utilities')

                    const { primaryColour, secondaryColour, logo } = config.utilitySettings

                    document.documentElement.style.setProperty('--brand-primary', primaryColour)
                    document.documentElement.style.setProperty('--brand-secondary', secondaryColour)

                    updateThemeColor(primaryColour)
                    if (logo) {
                        updateFavicon('icon', logo)
                        updateFavicon('apple-touch-icon', logo)
                        updateFavicon('shortcut icon', logo)
                        updateMaskIcon(logo, primaryColour)
                    }
                })
            )
    }
}
function updateThemeColor(color: string) {
    const themeColorMeta = document.querySelector<HTMLLinkElement>('meta[name="theme-color"]')
    if (themeColorMeta) {
        themeColorMeta.setAttribute('content', color)
    }

    // Update MS application TileColor if needed
    const msTileColorMeta = document.querySelector('meta[name="msapplication-TileColor"]')
    if (msTileColorMeta) {
        msTileColorMeta.setAttribute('content', color)
    }
}
function updateFavicon(type: string, newHref: string) {
    const link = document.querySelector<HTMLLinkElement>(`link[rel="${type}"]`)
    if (link) {
        link.href = newHref
    }
}
function updateMaskIcon(logo: string, color: string) {
    const maskIcon = document.querySelector<HTMLLinkElement>('link[rel="mask-icon"]')
    if (maskIcon) {
        maskIcon.href = logo
        maskIcon.setAttribute('color', color) // Update color for the mask icon
    }
}
