<section class="page" #top>
    <div class="container">
        <div class="row mb-3">
            <div class="col-lg-9">
                <h1>
                    @if (appointmentType$ | async; as appointmentType) {
                        @if (!bookingEngineResponse()) {
                            Book your <span class="text-primary fw-bolder">{{ appointmentType }}</span> appointment
                        } @else {
                            Confirmation of your
                            <span class="text-primary fw-bolder">{{ appointmentType }}</span> appointment
                        }
                    } @else {
                        @if (!bookingEngineResponse()) {
                            Book your appointment
                        } @else {
                            Confirmation of your appointment
                        }
                    }
                </h1>

                @if (customer(); as customer) {
                    <h4 class="mt-3">
                        <span class="small">{{ customer.prettyName }},</span>
                    </h4>
                }
                @if (!bookingEngineResponse()) {
                    @if (config()?.bookAppointmentNarrative; as narrative) {
                        <p>
                            {{ narrative }}
                        </p>
                    }
                    <p>
                        Please fill in your details and select your preferred timeslot for your appointment. We will
                        send you a confirmation email/sms to confirm your appointment.
                    </p>
                }
            </div>
        </div>

        <div class="row gx-5 position-relative">
            <ngx-loader [loading]="bookAppointmentStore.isPending()" [fixed]="false"
                ><img src="../assets/images/loader.svg"
            /></ngx-loader>
            <div class="col-md-12 col-xl-8 position-relative">
                @if (bookingEngineResponse(); as bookingResponse) {
                    <div class="row">
                        <div class="col-12">
                            @if (selectedTime(); as selectedTime) {
                                <div class="col-12 col-12 bg-success-subtle rounded-2 p-3">
                                    <div class="row align-items-center">
                                        <div class="col-3">
                                            <img
                                                class="img-fluid rounded-circle"
                                                src="{{ selectedTime.engineerLogo }}"
                                                alt="Engineer Picture" />
                                        </div>
                                        <div class="col-9">
                                            <h2>Thank You</h2>
                                            <p class="lead">
                                                We have received your booking for
                                                {{ this.form.controls.appointmentDate.value | date }}.
                                            </p>
                                            <p class="fw-bold fs-5">
                                                You will receive a confirmation SMS/Email the day before your
                                                appointment.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            }
                            @if (bookingResponse.paymentUrl) {
                                <div class="col-12 col-12 rounded-2 p-3 mt-3">
                                    <div class="row">
                                        <div class="col-12">
                                            <h2 class="text-warning">Pay Now?</h2>

                                            <p class="mt-3 fs-5">
                                                If you would like to pay for your appointment now, please click the
                                                button below to be taken to our secure payment gateway provided by
                                                Stripe.
                                            </p>

                                            <div class="d-grid g-2">
                                                <a
                                                    sfButton
                                                    size="lg"
                                                    color="success"
                                                    href="{{ bookingResponse.paymentUrl }}"
                                                    >Pay Now</a
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }

                @if (!bookingEngineResponse()) {
                    <div class="bg-light p-3 rounded-2">
                        @if (stage() === 'preform') {
                            @if (hasAirQuote) {
                                <ngb-alert type="light" [dismissible]="false">
                                    <p class="mb-0">
                                        Thank you for completing your Airquote estimate.<br /><br />
                                        Please complete the form below for one of our surveyors to come and complete a
                                        full survey and provide an accurate quotation.
                                    </p>
                                </ngb-alert>
                            }

                            <form [formGroup]="preform" class="row">
                                @if (!hasPreselectedAppointmentTypeId()) {
                                    <div class="mb-2">
                                        <sf-appointment-radio-selector
                                            [options]="config()?.forms!"
                                            (selectionChanged)="scroll(mobileinput)"
                                            formControlName="appointmentTypeId"></sf-appointment-radio-selector>
                                    </div>
                                }

                                <div class="col-12" #mobileinput>
                                    <sf-input
                                        size="lg"
                                        label="Mobile Number"
                                        inputId="mobile"
                                        formControlName="mobileNumber"></sf-input>
                                </div>
                                <div class="col-12">
                                    <sf-input-postcode
                                        size="lg"
                                        label="Postcode"
                                        inputId="postcode"
                                        formControlName="postcode"></sf-input-postcode>
                                </div>

                                @if (addressSuggestions().length) {
                                    <div class="col-12">
                                        <sf-label for="address" label="Select your address"></sf-label>
                                        <ng-select
                                            #addressSelector
                                            id="address"
                                            [items]="addressSuggestions()"
                                            bindLabel="address"
                                            bindValue="id"
                                            placeholder="Select your address"
                                            (change)="setAddress($event)"></ng-select>
                                    </div>
                                }
                                @if (postcodeError()) {
                                    <div class="col-12">
                                        <p class="text-danger">{{ postcodeError() }}</p>
                                    </div>
                                }
                                @if (!addressSuggestions().length) {
                                    <div class="col-12 mt-2">
                                        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <button
                                                sfButton
                                                type="submit"
                                                size="lg"
                                                color="primary"
                                                (click)="submitPreform()">
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                }
                            </form>
                        }
                        @if (stage() === 'form') {
                            <form [formGroup]="form" class="row">
                                @if (customer(); as customer) {
                                    <sf-customer-card [customer]="customer" class="mb-3"></sf-customer-card>
                                }

                                <div #datepicker></div>

                                @if (bookAppointmentStore.enabledDates()?.length) {
                                    <div class="col-md-6 mb-3 text-center text-md-start">
                                        <sf-label for="date" label="Select a date" [required]="true"></sf-label>
                                        <ngb-datepicker
                                            id="date"
                                            #dp
                                            id="date"
                                            (dateSelect)="selectDate($event)"
                                            [dayTemplate]="t"
                                            [markDisabled]="isDisabled"
                                            [minDate]="bookAppointmentStore.minDate()"
                                            [maxDate]="bookAppointmentStore.maxDate()"
                                            outsideDays="collapsed"
                                            weekdays="short"
                                            navigation="arrows"
                                            class="" />
                                        <ng-template
                                            #t
                                            let-date
                                            let-disabled="disabled"
                                            let-focused="focused"
                                            let-selected="selected">
                                            <span
                                                class="custom-day"
                                                [class.selected]="selected"
                                                [class.focused]="focused"
                                                [class.hovered]="date === hoveredDate"
                                                [class.available]="isDateEnabled(date)"
                                                [class.disabled]="disabled"
                                                (mouseenter)="hoveredDate = date"
                                                (mouseleave)="hoveredDate = null">
                                                {{ date.day }}
                                            </span>
                                        </ng-template>
                                    </div>
                                    <div class="col-md-6 text-center text-md-start mb-3" #timepicker>
                                        @if (bookAppointmentStore.availableTimes(); as availableTimes) {
                                            <sf-label
                                                class="d-block mt-3"
                                                for="date"
                                                label="{{
                                                    form.controls.appointmentDate.value | date: 'EEEE d MMMM y'
                                                }}"
                                                [required]="true"></sf-label>

                                            @if (!availableTimes.availableStartTimes.length) {
                                                <p class="text-danger">
                                                    There are no available times available, please select another date.
                                                </p>
                                            }

                                            <div class="d-grid gap-2">
                                                @for (
                                                    time of availableTimes.availableStartTimes;
                                                    track time.availableStartTime
                                                ) {
                                                    <button
                                                        class="btn btn-outline-primary border-1"
                                                        [class.active]="
                                                            time.availableStartTime ===
                                                            form.controls.appointmentDate.value
                                                        "
                                                        (click)="selectTime(time)">
                                                        Preferred time: &nbsp;&nbsp;&nbsp;{{
                                                            time.availableStartTime | date: 'HH:mm a'
                                                        }}
                                                    </button>
                                                }
                                            </div>
                                        }
                                    </div>
                                }

                                @if (selectedTime()) {
                                    <div class="row" #confirmation>
                                        @if (selectedTime(); as selectedTime) {
                                            <div class="col-12">
                                                <ngb-alert class="mt-3" type="light" [dismissible]="false">
                                                    <div class="row align-items-center">
                                                        <div class="col-3">
                                                            <img
                                                                class="img-fluid rounded-circle"
                                                                src="{{ selectedTime.engineerLogo }}"
                                                                alt="Engineer Picture" />
                                                        </div>
                                                        <div class="col-9">
                                                            <h4>Please Confirm your booking information</h4>
                                                            <p class="mb-3">
                                                                Your engineer {{ selectedTime.engineerName }} will be
                                                                with you on
                                                                {{
                                                                    form.controls.appointmentDate.value
                                                                        | date: 'dd/MM/yyyy'
                                                                }}
                                                                . You will receive an SMS confirming your appointment
                                                                window the day before your appointment
                                                            </p>
                                                            <p class="">
                                                                Fill out below and click confirm to schedule your
                                                                {{ appointmentType$ | async }} appointment
                                                            </p>
                                                        </div>
                                                    </div>
                                                </ngb-alert>
                                            </div>
                                        }
                                    </div>
                                }

                                @if (!customer() && selectedTime()) {
                                    <div class="col-12 col-sm-2" #formfields>
                                        <sf-label for="title" label="Title" [required]="true"></sf-label>
                                        <ng-select
                                            id="title"
                                            [items]="titles$ | async"
                                            bindLabel="title"
                                            bindValue="title"
                                            [clearable]="false"
                                            formControlName="title"></ng-select>

                                        @if (form.controls.title.touched && form.controls.title.errors) {
                                            <p class="text-danger small mt-1 mb-0">Please select your title</p>
                                        }
                                    </div>

                                    <div class="col-12 col-sm-5">
                                        <sf-input
                                            size="lg"
                                            label="First Name"
                                            inputId="firstName"
                                            formControlName="firstName"></sf-input>
                                    </div>
                                    <div class="col-12 col-sm-5">
                                        <sf-input
                                            size="lg"
                                            label="Last Name"
                                            inputId="lastName"
                                            formControlName="lastName"></sf-input>
                                    </div>
                                    <div class="col-12">
                                        <sf-input
                                            size="lg"
                                            label="Email"
                                            inputId="email"
                                            formControlName="emailAddress"></sf-input>
                                    </div>
                                    <div class="col-12">
                                        <sf-input
                                            size="lg"
                                            label="Mobile Phone"
                                            inputId="phone"
                                            formControlName="mobileNumber"></sf-input>
                                    </div>
                                    <div class="col-12">
                                        <sf-input
                                            size="lg"
                                            label="Address"
                                            inputId="address"
                                            formControlName="fullAddress"></sf-input>
                                    </div>
                                }
                                @if (selectedTime()) {
                                    <div class="mt-3">
                                        <sf-input-textarea
                                            label="{{
                                                form.controls.appointmentTypeId.value === 3 ||
                                                form.controls.appointmentTypeId.value === 6
                                                    ? 'Description of the problem'
                                                    : 'Notes'
                                            }}"
                                            inputId="notes"
                                            formControlName="notes"
                                            [maxLength]="1000">
                                        </sf-input-textarea>
                                    </div>
                                }
                                @if (bookAppointmentStore.enabledDates()?.length === 0) {
                                    <div class="col-12 mt-3">
                                        <ngb-alert type="danger" [dismissible]="false">
                                            <h4>Sorry!</h4>
                                            <p class="mb-0">
                                                We do not have any available dates for this postcode area.
                                                <br />
                                                Please get in touch via phone, email or send a general enquiry.
                                            </p>
                                        </ngb-alert>
                                    </div>
                                }
                            </form>

                            @if (bookAppointmentStore.error()) {
                                <div class="row mt-3">
                                    <div class="col-12">
                                        <ngb-alert type="danger">
                                            {{ bookAppointmentStore.error() }}
                                        </ngb-alert>
                                    </div>
                                </div>
                            }

                            <div class="row">
                                <div class="col-12">
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-5 mb-3">
                                        <button
                                            sfButton
                                            type="button"
                                            color="success"
                                            size="lg"
                                            (click)="confirmBooking()">
                                            Confirm
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                @if (!bookingEngineResponse() && !hasPreselectedAppointmentTypeId()) {
                    <div class="mt-3">
                        <button sfButton type="button" color="dark" size="sm" (click)="reset()">Start Again</button>
                    </div>
                }
            </div>

            <div class="col-md-12 col-xl-4 mt-3 mt-xl-0">
                <sf-cta-mobile-app-vertical></sf-cta-mobile-app-vertical>
            </div>
        </div>
    </div>
</section>
