import { Component, Input, inject } from '@angular/core'
import { DatePipe } from '@angular/common'
import { RouterLink } from '@angular/router'
import { AirQuotePackCardComponent } from '../../components/air-quote-pack-card/air-quote-pack-card.component'
import { AirQuoteStore } from '../../air-quote.store'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { PriceConfiguratorComponent } from '../../components/price-configurator/price-configurator.component'
import { AirQuoteBundleItem } from 'projects/quoting-app/src/app/shared/interfaces/bundles/air-quote-bundle-item.interface'
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap'

@Component({
    selector: 'sf-bundles',
    standalone: true,
    imports: [
        RouterLink,
        NgbAlertModule,
        FontAwesomeModule,
        AirQuotePackCardComponent,
        PriceConfiguratorComponent,
        DatePipe,
    ],
    templateUrl: './air-quote-bundles.component.html',
    styleUrl: './air-quote-bundles.component.scss',
})
export class AirQuoteBundlesComponent {
    airQuoteStore = inject(AirQuoteStore)

    @Input()
    set id(id: string) {
        if (!id) return

        this.airQuoteStore.loadExistingQuote(id)
    }

    onRequestCallback(bundle: AirQuoteBundleItem): void {
        this.airQuoteStore.openRequestCallbackModal(bundle.packId)
    }
}
