<div class="checkbox-container mb-1">
    <div class="checkbox-label">
        <sf-label [label]="label" [for]="inputId"></sf-label>
    </div>
    <input
        type="checkbox"
        class="btn-check"
        [id]="inputId"
        autocomplete="off"
        [value]="checked"
        [checked]="checked"
        [disabled]="disabled" />
    <div class="checkbox-inner">
        <label
            class="btn checkbox-btn"
            [class.btn-success]="checked"
            [class.btn-outline-primary]="!checked"
            [for]="inputId"
            (click)="toggle()"
            ><fa-icon class="float-start me-2" [icon]="['far', checked ? 'square-check' : 'square']"></fa-icon>
            {{ checked ? activeText : inactiveText }}</label
        >
    </div>
</div>
