<div class="quote-content">
    <div class="container">
        <div class="row">
            <div class="col-12">
                @if (airQuoteStore.currentQuestion(); as currentQuestion) {
                    <ngx-quote-question
                        [question]="currentQuestion.question"
                        [explanation]="currentQuestion.explanation"></ngx-quote-question>

                    <ngx-quote-answers
                        [answers]="airQuoteStore.answersForQuestion()"
                        (selectAnswer)="airQuoteStore.selectAnswer($event)"></ngx-quote-answers>

                    @if (!airQuoteStore.isFirstQuestion()) {
                        <ngx-quote-previous-button
                            (previous)="airQuoteStore.goToPreviousQuestion()"></ngx-quote-previous-button>
                    }
                }
            </div>
        </div>
    </div>
</div>
