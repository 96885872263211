<section class="page">
    <div class="container" [formGroup]="form">
        <div class="row mb-3">
            <div class="col-lg-9">
                <h1>Your Enquiry</h1>
                <p>Please fill in your details and we will get back to you as soon as possible.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-8">
                <div class="bg-light p-3 rounded-2">
                    @if (!submitted()) {
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <sf-input
                                    label="First Name"
                                    size="lg"
                                    inputId="firstName"
                                    formControlName="firstName"></sf-input>
                            </div>
                            <div class="col-12 col-sm-6">
                                <sf-input
                                    label="Last Name"
                                    size="lg"
                                    inputId="lastName"
                                    formControlName="lastName"></sf-input>
                            </div>
                            <div class="col-12">
                                <sf-input-postcode
                                    size="lg"
                                    label="Postcode"
                                    inputId="postcode"
                                    formControlName="postcode"></sf-input-postcode>
                            </div>
                            <div class="col-12">
                                <sf-input
                                    label="Email"
                                    size="lg"
                                    inputId="email"
                                    formControlName="emailAddress"></sf-input>
                            </div>
                            <div class="col-12">
                                <sf-input label="Phone" size="lg" inputId="phone" formControlName="phone"></sf-input>
                            </div>
                            <div class="col-12">
                                <sf-input-textarea
                                    [maxLength]="1000"
                                    [rows]="5"
                                    label="Message"
                                    inputId="message"
                                    formControlName="message"></sf-input-textarea>
                            </div>
                            @if (error()) {
                                <div class="col-12">
                                    <ngb-alert type="danger" [dismissible]="false">
                                        <p class="mb-0">
                                            There was a problem. Please ensure the form is completed correctly and try
                                            again. If the problem persists. Please call or email.
                                        </p>
                                    </ngb-alert>
                                </div>
                            }
                            <div class="col-12">
                                <div class="d-grid">
                                    <button sfButton type="button" color="primary" size="lg" (click)="submit()">
                                        Send my enquiry
                                    </button>
                                </div>
                            </div>
                        </div>
                    } @else {
                        <div class="row">
                            <div class="col-12">
                                <ngb-alert class="mb-0" type="success" [dismissible]="false">
                                    <h5>Thank You</h5>
                                    <p class="mb-0">
                                        We have received your enquiry and will get back to you as soon as possible.
                                    </p>
                                </ngb-alert>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <sf-cta-mobile-app-vertical></sf-cta-mobile-app-vertical>
            </div>
        </div>
    </div>
</section>
