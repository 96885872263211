<!-- @if (settings.phoneNumber || settings.emailAddress) {
    <div class="container-fluid contact-bar">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-end">
                        <div class="ms-auto">
                            @if (settings.websiteUrl) {
                                W:&nbsp;
                                <a href="{{ settings.websiteUrl }}" class="me-3" target="_blank">{{
                                    settings.websiteUrl
                                }}</a>
                            }
                            @if (settings.emailAddress) {
                                E:&nbsp;
                                <a href="mailto:{{ settings.emailAddress }}" class="me-3">{{
                                    settings.emailAddress
                                }}</a>
                            }
                            @if (settings.phoneNumber) {
                                T:&nbsp;<a href="tel:{{ settings.phoneNumber }}">{{ settings.phoneNumber }}</a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
} -->

<header class="shadow site-header">
    <nav class="navbar navbar-light w-100">
        <div class="container">
            <a routerLink="/" class="navbar-brand">
                @if (settings.logo) {
                    <img src="{{ settings.logo }}" alt="{{ companyName }} logo" />
                } @else {
                    {{ companyName }}
                }
            </a>
            <div class="d-flex">
                <a sfButton color="dark" class="d-none d-sm-inline-block" routerLink="/enquiry/book-appointment"
                    >Book Appointment</a
                >
            </div>
        </div>
    </nav>
</header>
