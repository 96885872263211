<div class="modal-header">
    <h4 class="modal-title">Request a Callback</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <ngb-alert [dismissible]="false" type="light"
        >Please tell us a bit about yourself and we'll get back to you as soon as possible.</ngb-alert
    >

    <form [formGroup]="form">
        <div class="row g-2">
            <div class="col-12">
                <sf-floating-label-input
                    [autofocus]="true"
                    label="First Name"
                    inputId="firstName"
                    formControlName="firstName"></sf-floating-label-input>
            </div>
            <div class="col-12">
                <sf-floating-label-input
                    label="Last Name"
                    inputId="lastName"
                    formControlName="lastName"></sf-floating-label-input>
            </div>
            <div class="col-12">
                <sf-floating-label-postcode-input
                    label="Postcode"
                    inputId="postcode"
                    formControlName="postcode"></sf-floating-label-postcode-input>
            </div>
            <div class="col-12">
                <sf-floating-label-input
                    label="Email"
                    inputId="emailAddress"
                    formControlName="emailAddress"></sf-floating-label-input>
            </div>
            <div class="col-12">
                <sf-floating-label-input
                    label="Phone Number"
                    inputId="phone"
                    formControlName="phone"></sf-floating-label-input>
            </div>
        </div>
        <ngb-alert class="mt-3" type="light" [dismissible]="false">
            <p>
                By continuing, you must consent to your personal data entered above being used for the purpose of
                contacting you to arrange a free home survey visit.
            </p>
            <p>
                We use <a href="https://www.surefiresoftware.co.uk" target="_blank">www.surefiresoftware.co.uk</a> for
                our Quote Engine and their Privacy Policy explains how they collect and store your personal data.
            </p>

            <sf-toggle
                class="mt-3"
                activeText="Yes I consent"
                inactiveText="Yes I consent"
                inputId="confirmed"
                formControlName="confirmed"></sf-toggle>
        </ngb-alert>
    </form>
    @if (error()) {
        <ngb-alert type="danger">{{ error() }}</ngb-alert>
    }
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Cancel</button>
    <button type="button" class="btn btn-success" (click)="submit()">Submit</button>
</div>
