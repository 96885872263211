<div class="input-group has-validation">
    <div class="form-floating" [class.is-invalid]="control.touched && control.dirty && control.errors">
        <textarea
            #input
            class="form-control"
            [class.form-control-sm]="size == 'sm'"
            [class.form-control-lg]="size == 'lg'"
            [required]="isRequired"
            [id]="inputId"
            [formControl]="control"
            [style.height.px]="height"
            autocomplete="none"
            [readOnly]="isReadOnly"
            [placeholder]="placeholder"></textarea>
        <label [for]="inputId">{{ label + (isRequired ? '*' : '') }}</label>
    </div>
    @if (control.touched && control.dirty && control.errors) {
        @for (err of control.errors | keyvalue; track err) {
            <div class="invalid-feedback">
                {{ errorMessages[err.key] }}
            </div>
        }
    }
</div>

<div class="d-flex mt-2">
    @if (maxLength > 0) {
        <span class="ms-auto small">{{ control.value?.length || 0 }} / {{ maxLength }}</span>
    }
</div>
