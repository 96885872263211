<div class="my-plumber-cta mt-3 mt-md-0">
    <div class="row">
        <div class="col-12">
            <div class="bg-light p-4 rounded-2">
                <div class="row gx-1">
                    <div class="col-3 position-relative">
                        <img
                            class="img-fluid sample-image"
                            src="../../../../assets/images/my-plumber.png"
                            alt="iOS sample image" />
                    </div>
                    <div class="col-9">
                        <h3>Download the app</h3>
                        <p>
                            Keep track of your upcoming appointments, view your appliances, request appointments and
                            track engineer en-route.
                        </p>
                        <a
                            href="https://apps.apple.com/gb/app/my-plumber/id1534869760?itsct=apps_box_link&itscg=30200"
                            target="_blank">
                            <img
                                src="../../../../../assets/images/ios-black.svg"
                                alt="iOS download image"
                                class="img-fluid" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
