import { CurrencyPipe, JsonPipe, PercentPipe } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, effect, inject, input, model, signal } from '@angular/core'
import { AirQuoteSettings } from 'projects/quoting-app/src/app/shared/interfaces/air-quote-settings.interface'
import { FinanceCalculatorService } from './finance-calculator.service'
import { JsonpClientBackend } from '@angular/common/http'
import { FormsModule } from '@angular/forms'
import { RouterLink } from '@angular/router'

@Component({
    selector: 'sf-price-configurator',
    standalone: true,
    imports: [RouterLink, FormsModule, CurrencyPipe, JsonPipe, PercentPipe],
    templateUrl: './price-configurator.component.html',
    styleUrl: './price-configurator.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FinanceCalculatorService],
})
export class PriceConfiguratorComponent {
    #financeCalculatorService = inject(FinanceCalculatorService)

    price = input.required<number>()
    financeSettings = input.required<AirQuoteSettings>()

    yearSelector = model(10)
    showFinance = signal(false)

    constructor() {
        effect(() => {
            const { financeInterestFreePeriod, financePeriod } = this.financeSettings()

            setTimeout(() => {
                this.yearSelector.update(() => Math.max(financeInterestFreePeriod, financePeriod))
            }, 50)
        })
    }

    toggleFinance(): void {
        this.showFinance.update(() => !this.showFinance())
    }

    maxFinancePeriod = computed(() => {
        const { financePeriod, financeInterestFreePeriod } = this.financeSettings()
        return Math.max(financePeriod, financeInterestFreePeriod)
    })

    financeOptions = computed(() => {
        const selectedFinancePeriod = this.yearSelector()
        const { apr, depositPercentage, financeInterestFreePeriod, financePeriod } = this.financeSettings()

        return this.#financeCalculatorService.calculate(
            this.price(),
            depositPercentage,
            apr,
            financePeriod,
            financeInterestFreePeriod,
            selectedFinancePeriod
        )
    })
}
