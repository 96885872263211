import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Customer } from '../shared/interfaces/customer/customer.interface'
import { Observable, map } from 'rxjs'
import { environment } from '../../environments/environment'
import { ApiResponse } from 'projects/sf-quoting-lib/src/lib/interfaces/api-response.interface'

@Injectable({
    providedIn: 'root',
})
export class CustomerService {
    #httpClient = inject(HttpClient)

    checkIfCustomerExists(
        email: string | null,
        mobileNumber: string | null,
        postcode: string
    ): Observable<Customer | null> {
        let params = new HttpParams().set('postcode', postcode)

        if (email) {
            params = params.set('emailAddress', email)
        }

        if (mobileNumber) {
            params = params.set('mobileNumber', mobileNumber)
        }

        return this.#httpClient
            .get<ApiResponse<Customer>>(`${environment.baseUrl}customer/exists`, { params })
            .pipe(map(response => response.result))
    }

    getCustomerByGuid(guid: string): Observable<Customer> {
        return this.#httpClient
            .get<ApiResponse<Customer>>(`${environment.baseUrl}customer/${guid}`)
            .pipe(map(response => response.result))
    }
}
