import { Component, inject } from '@angular/core'
import { ConfigService } from '../../services/config.service'

@Component({
    selector: 'footer[sf-company-footer]',
    standalone: true,
    imports: [],
    templateUrl: './company-footer.component.html',
    styleUrl: './company-footer.component.scss',
})
export class CompanyFooterComponent {
    #configService = inject(ConfigService)

    config = this.#configService.config

    year = new Date().getFullYear()
}
