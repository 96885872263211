import { Component, Input, input } from '@angular/core'
import { RouterLink } from '@angular/router'
import { FaIconLibraryInterface, FontAwesomeModule, IconName, IconPrefix } from '@fortawesome/angular-fontawesome'
import { ButtonComponent } from 'projects/quoting-app/src/app/shared/components/form/button/button.component'

@Component({
    selector: 'sf-utility-card',
    standalone: true,
    imports: [RouterLink, FontAwesomeModule, ButtonComponent],
    templateUrl: './utility-card.component.html',
    styleUrl: './utility-card.component.scss',
    host: {
        class: 'card border-2 h-100',
    },
})
export class UtilityCardComponent {
    @Input() name = ''
    @Input() subtitle = ''
    @Input() description = ''
    @Input() link = ''
    @Input() buttonText = ''

    @Input() prefix: IconPrefix = 'far'
    @Input() icon: IconName = 'calendar-check'
}
