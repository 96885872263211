<div class="card my-2">
    <h5 class="card-header">Existing Customer?</h5>
    <div class="card-body">
        <p class="mb-2">Are you an existing customer? If so, save time filling in the form.</p>
        <button sfButton type="button" color="dark" size="sm" (click)="open(content)">Find my details</button>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Existing Customer</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <div class="row g-2" [formGroup]="form">
            <div class="col-12">
                <ngb-alert type="light" [dismissible]="false">
                    <p class="mb-0">
                        We can check to see if your an existing customer by using your email address and postcode.
                    </p>
                </ngb-alert>
            </div>
            <div class="col-12">
                <sf-floating-label-input
                    label="Email"
                    inputId="email"
                    formControlName="email"></sf-floating-label-input>
            </div>
            <div class="col-12">
                <sf-floating-label-postcode-input
                    label="Postcode"
                    inputId="postcode"
                    formControlName="postcode"></sf-floating-label-postcode-input>
            </div>
            <div class="col-12">
                @if (message()) {
                    <p class="text-danger">{{ message() }}</p>
                }
                <div class="d-grid">
                    <button
                        sfButton
                        type="button"
                        size="lg"
                        color="success"
                        [disabled]="form.invalid"
                        (click)="check()">
                        Search
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button sfButton type="button" color="dark" (click)="modal.dismiss('Close click')">Cancel</button>
    </div>
</ng-template>
