import { APP_INITIALIZER, ApplicationConfig, DEFAULT_CURRENCY_CODE, LOCALE_ID } from '@angular/core'
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router'
import { routes } from './app.routes'
import { provideHttpClient, withInterceptors } from '@angular/common/http'
import { catchError, of } from 'rxjs'
import { httpInterceptor } from './interceptors/http.interceptor'
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap'
import { CustomDateAdapter, CustomDateParserFormatter } from 'projects/sf-shared-lib/src/public-api'
import { provideEnvironmentNgxMask } from 'ngx-mask'
import { ConfigService } from './services/config.service'
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha'
import { environment } from '../environments/environment'

export function initializeApp(configService: ConfigService) {
    return () => {
        return configService.initialize(getSubdomain()).pipe(
            catchError(() => {
                return of(null)
            })
        )
    }
}

function getSubdomain(): string {
    const domain = window.location.hostname
    let subdomain = ''
    if (domain.indexOf('.') < 0 || domain.split('.')[0] === 'www') {
        subdomain = ''
    } else {
        subdomain = domain.split('.')[0]
    }
    return subdomain
}

export const appConfig: ApplicationConfig = {
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [ConfigService],
            multi: true,
        },
        provideRouter(
            routes,
            withInMemoryScrolling({ scrollPositionRestoration: 'top', anchorScrolling: 'enabled' }),
            withComponentInputBinding()
            // withDebugTracing()
        ),
        provideEnvironmentNgxMask(),
        provideHttpClient(withInterceptors([httpInterceptor])),
        {
            provide: LOCALE_ID,
            useValue: 'en-GB',
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'GBP',
        },
        {
            provide: NgbDateAdapter,
            useClass: CustomDateAdapter,
        },
        {
            provide: NgbDateParserFormatter,
            useClass: CustomDateParserFormatter,
        },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.googleRecaptchaKey,
        },
    ],
}
