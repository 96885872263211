import { AfterViewInit, Component, ElementRef, Input, OnInit, forwardRef, viewChild } from '@angular/core'
import { ControlValueAccessorDirective } from '../../../directives/control-value-accessor.directive'
import { KeyValuePipe } from '@angular/common'
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms'
import { LabelComponent } from '../label/label.component'

@Component({
    selector: 'sf-floating-label-textarea-input',
    standalone: true,
    imports: [ReactiveFormsModule, LabelComponent, KeyValuePipe],
    templateUrl: './floating-label-textarea-input.component.html',
    styleUrl: './floating-label-textarea-input.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FloatingLabelTextareaInputComponent),
            multi: true,
        },
    ],
})
export class FloatingLabelTextareaInputComponent<T>
    extends ControlValueAccessorDirective<T>
    implements OnInit, AfterViewInit
{
    @Input() inputId = ''
    @Input() label = ''
    @Input() customErrorMessages: Record<string, string> = {}
    @Input() placeholder = ''
    @Input() description = ''
    @Input() size: 'sm' | 'md' | 'lg' = 'md'
    @Input() isReadOnly = false

    @Input() autofocus = false

    @Input() height = 'auto'

    inputEl = viewChild<ElementRef>('input')

    errorMessages: Record<string, string> = {
        required: 'This field is required',
        maxlength: 'This field has too many characters',
        minlength: 'This field has too many characters',
    }

    // If you want to show a counter for the characters remaining, set this to the max length of the field    @Input() maxLength = 0
    @Input() maxLength = 0

    override ngOnInit(): void {
        this.errorMessages = { ...this.errorMessages, ...this.customErrorMessages }
        super.ngOnInit()
    }

    ngAfterViewInit(): void {
        if (this.autofocus) {
            this.inputEl()?.nativeElement.focus()
        }
    }
}
