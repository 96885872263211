import { Component, inject } from '@angular/core'
import { Observable } from 'rxjs'
import { AsyncPipe, JsonPipe } from '@angular/common'
import { AirQuoteService } from '../../../../services/air-quote.service'
import { AirQuoteInitialization } from '../../../../shared/interfaces/air-quote-initialization.interface'
import { HeaderComponent } from '../../../../layout/header/header.component'
import { ProgressComponent } from '../../../../layout/progress/progress.component'
import { AirQuoteStore } from '../../air-quote.store'
import {
    QuoteAnswersComponent,
    QuoteMasterComponent,
    QuotePreviousButtonComponent,
    QuoteQuestionComponent,
} from 'projects/sf-quoting-lib/src/public-api'

@Component({
    selector: 'app-quote',
    standalone: true,
    imports: [
        HeaderComponent,
        ProgressComponent,
        QuoteMasterComponent,
        QuoteQuestionComponent,
        QuoteAnswersComponent,
        QuotePreviousButtonComponent,
        AsyncPipe,
        JsonPipe,
    ],
    templateUrl: './air-quote-wizard.component.html',
    styleUrl: './air-quote-wizard.component.scss',
})
export class AirQuoteWizardComponent {
    airQuoteStore = inject(AirQuoteStore)
    airQuoteService = inject(AirQuoteService)

    airQuote$!: Observable<AirQuoteInitialization>
}
