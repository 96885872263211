import { Component } from '@angular/core'
import { RouterOutlet } from '@angular/router'

@Component({
    selector: 'sf-enquiry-shell',
    standalone: true,
    imports: [RouterOutlet],
    templateUrl: './enquiry-shell.component.html',
    styleUrl: './enquiry-shell.component.scss',
})
export class EnquiryShellComponent {}
