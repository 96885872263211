<div class="mb-2">
    <sf-label [label]="label" for="{{ inputId }}" [required]="isRequired"></sf-label>
    <textarea
        class="form-control"
        [class.form-control-sm]="size == 'sm'"
        [class.form-control-lg]="size == 'lg'"
        [required]="isRequired"
        [id]="inputId"
        [formControl]="control"
        autocomplete="none"
        [placeholder]="placeholder"
        [rows]="rows"></textarea>

    @if (control.touched && control.dirty && control.errors) {
        @for (err of control.errors | keyvalue; track err) {
            <div class="invalid-feedback">
                {{ errorMessages[err.key] }}
            </div>
        }
    }

    <div class="d-flex mt-2">
        @if (maxLength > 0) {
            <span class="ms-auto small">{{ control.value?.length || 0 }} / {{ maxLength }}</span>
        }
    </div>
</div>
