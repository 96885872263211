import { Component, OnInit, inject } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { StripeService } from 'projects/quoting-app/src/app/services/stripe.service'

@Component({
    selector: 'sf-stripe-success',
    standalone: true,
    imports: [],
    templateUrl: './stripe-success.component.html',
    styleUrl: './stripe-success.component.scss',
})
export class StripeSuccessComponent implements OnInit {
    #route = inject(ActivatedRoute)
    #stripeService = inject(StripeService)

    ngOnInit(): void {
        this.#route.queryParamMap.subscribe(params => {
            const sessionId = params.get('sessionId')
            const cid = params.get('cid')

            if (sessionId && cid) {
                this.#stripeService.markPaymentAsPaid({ sessionId, cid: Number(cid) }).subscribe({
                    next: () => {},
                    error: () => {},
                })
            }
        })
    }
}
