import { Component } from '@angular/core';

@Component({
  selector: 'sf-cta-mobile-app-horizontal',
  standalone: true,
  imports: [],
  templateUrl: './cta-mobile-app-horizontal.component.html',
  styleUrl: './cta-mobile-app-horizontal.component.scss'
})
export class CtaMobileAppHorizontalComponent {

}
