import { Component, inject } from '@angular/core'
import { RouterModule } from '@angular/router'
import { FooterComponent } from './layout/footer/footer.component'
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import {
    faCamera,
    faCheckCircle,
    faEarthEurope,
    faEnvelopeCircleCheck,
    faHouse,
    faListCheck,
    faPhone,
    faRepeat,
    faSquareEnvelope,
    faSquarePhone,
    faSquare as fasSquare,
    faSquareCheck as fasSquareCheck,
} from '@fortawesome/free-solid-svg-icons'
import {
    faCalendarCheck,
    faSquare as farSquare,
    faSquareCheck as farSquareCheck,
} from '@fortawesome/free-regular-svg-icons'
import { LoadingService } from './services/loading.service'
import { Observable, delay } from 'rxjs'
import { AsyncPipe } from '@angular/common'
import { LoaderComponent } from 'projects/sf-quoting-lib/src/public-api'

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [LoaderComponent, FontAwesomeModule, RouterModule, FooterComponent, AsyncPipe],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    #loadingService = inject(LoadingService)

    loading$!: Observable<boolean>
    title = 'quoting-app'

    constructor(library: FaIconLibrary) {
        library.addIcons(
            faCheckCircle,
            faRepeat,
            fasSquare,
            fasSquareCheck,
            farSquare,
            farSquareCheck,
            faCalendarCheck,
            faListCheck,
            faEnvelopeCircleCheck,
            faSquarePhone,
            faSquareEnvelope,
            faEarthEurope,
            faCamera,
            faHouse,
            faPhone
        )
        this.loading$ = this.#loadingService.loading$.pipe(delay(0))
    }
}
