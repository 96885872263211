import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { map, Observable, of, tap } from 'rxjs'
import { environment } from '../../environments/environment'
import { Title } from '../shared/interfaces/title.interface'
import { ApiResponse } from 'projects/sf-quoting-lib/src/lib/interfaces/api-response.interface'

@Injectable({
    providedIn: 'root',
})
export class TitleService {
    #httpClient = inject(HttpClient)

    getTitles(): Observable<Title[]> {
        return this.#httpClient
            .get<ApiResponse<Title[]>>(`${environment.baseUrl}titles`)
            .pipe(map(response => response.result))
    }
}
