@if (airQuoteStore.isFulfilled()) {
    <div class="container-fluid bg-light package-hero">
        <div class="container pt-5">
            <div class="row">
                <div class="col-12 text-center">
                    <h1>Recommended packages, subject to survey</h1>
                    <p>
                        By selecting a package, you are not committing to a price, we just need to know what your
                        preferred selection would be.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="container my-3">
        @if (
            (airQuoteStore.details()?.hasRequestedCallback || airQuoteStore.details()?.hasRequestedSurvey) &&
            !airQuoteStore.appointment()
        ) {
            <div class="row">
                <div class="col-12">
                    <ngb-alert type="success" [dismissible]="false">
                        <h4 class="">Thank you</h4>
                        <p class="mb-0">Your request has been submitted successfully. We will be in touch soon.</p>
                    </ngb-alert>
                </div>
            </div>
        }

        @if (airQuoteStore.appointment(); as appointment) {
            <div class="row">
                <div class="col-12">
                    <ngb-alert type="success" [dismissible]="false">
                        <h4 class="">Appointment Scheduled</h4>
                        <p class="mb-0">
                            Your appointment has been scheduled for
                            {{ appointment.date | date: 'dd/MM/yyyy' }} at {{ appointment.date | date: 'HH:mm a' }} with
                            {{ appointment.staffName }}.
                        </p>
                    </ngb-alert>
                </div>
            </div>
        }
        <div class="row">
            <div class="col-12">
                @for (pack of airQuoteStore.bundlesEntities(); track pack.id) {
                    <sf-air-quote-pack-card
                        [financeSettings]="airQuoteStore.settings()!"
                        [pack]="pack"
                        [hideRequestButtons]="airQuoteStore.hideRequestButtons()"
                        (selectedBundle)="airQuoteStore.selectBundle($event)"
                        (requestCallback)="onRequestCallback($event)"
                        (requestHomeSurvey)="airQuoteStore.openRequestSurveyModal($event.packId, 'home')"
                        (requestVirtualSurvey)="
                            airQuoteStore.openRequestSurveyModal($event.packId, 'virtual')
                        "></sf-air-quote-pack-card>
                }
            </div>
        </div>
    </div>

    <div class="summary-buttons d-flex justify-content-center">
        <div class="bg-white shadow p-2 rounded-3 gap-2 d-inline-flex flex-row justify-content-center">
            <div>
                <button type="button" class="btn" (click)="airQuoteStore.restartQuote()">
                    <fa-icon class="me-2" [icon]="['fas', 'repeat']"></fa-icon> Start again
                </button>
            </div>
        </div>
    </div>
}
