import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
    selector: 'sf-progress',
    standalone: true,
    imports: [],
    templateUrl: './progress.component.html',
    styleUrl: './progress.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressComponent {
    #width = 0

    @Input() set progress(value: number) {
        this.#width = value
    }

    get progress(): number {
        return this.#width
    }
}
