import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

export function UKPostcodeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value

        if (!value) {
            // Return null if the value is empty, indicating no validation error
            return null
        }

        // Define regular expression patterns for the main UK postcode formats
        const postcodePattern = /^([a-zA-Z]{1,2}\d[a-zA-Z\d]? ?\d[a-zA-Z]{2}|GIR ?0A{2})$/i

        if (!postcodePattern.test(value)) {
            return { invalidPostcode: true }
        }

        return null
    }
}
