@if (airQuoteStore.selectedBundle(); as bundle) {
    <div class="container-fluid bg-light py-5">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="image-container">
                        <img
                            class="img-fluid manufacturer-logo"
                            src="{{ bundle.manufacturerImageUrl }}"
                            alt="Manufacturer logo" />
                        <img class="img-fluid" src="{{ bundle.imageUrl }}" alt="Image for {{ bundle.name }}" />
                    </div>
                </div>
                <div class="col-md-5 offset-md-1">
                    <div class="px-5">
                        <div class="text-center mb-4">
                            <h2>{{ bundle.name }}</h2>
                            <p class="fw-bold">{{ bundle.description }}</p>
                            <p>{{ bundle.longDescription }}</p>

                            <div class="d-flex flex-row flex-wrap justify-content-center gap-1 my-2">
                                @if (bundle.warrantyYears) {
                                    <span class="fs-6 badge badge-lg bg-info"
                                        >{{ bundle.warrantyYears }} year warranty</span
                                    >
                                }
                                @if (
                                    airQuoteStore.settings()?.hasFinance &&
                                    airQuoteStore.settings()!.financeInterestFreePeriod > 0
                                ) {
                                    <span class="fs-6 badge badge-lg bg-info">0% Finance</span>
                                }
                            </div>

                            <sf-price-configurator
                                [price]="bundle.price"
                                [financeSettings]="airQuoteStore.settings()!"></sf-price-configurator>
                        </div>

                        @for (include of bundle.includes; track include; let first = $first) {
                            <div
                                [class.border-top]="!first"
                                class="d-flex flex-row align-items-center gap-2 small border-1">
                                <div class="py-2 flex-shrink-1">
                                    <fa-icon class="text-success" [icon]="['fas', 'check-circle']"></fa-icon>
                                </div>
                                <div class="py-2 flex-grow-1">{{ include.description }}</div>
                            </div>
                        }

                        <div class="d-grid gap-2 mt-5">
                            @if (!airQuoteStore.hideRequestButtons()) {
                                <button
                                    type="button"
                                    class="btn btn-lg btn-outline-success mb-5"
                                    (click)="open(content)">
                                    Next
                                </button>
                            }

                            <a routerLink="../../bundles" class="btn btn-lg btn-outline-secondary">Back</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container my-5">
        <div class="row">
            <div class="col-12 offset-md-2 col-md-8">
                <h2>What's included?</h2>
                <div ngbAccordion [closeOthers]="true">
                    @for (product of airQuoteStore.selectedBundle()?.products; track product.id) {
                        <div ngbAccordionItem>
                            <h2 ngbAccordionHeader>
                                <button ngbAccordionButton>{{ product.name }}</button>
                            </h2>
                            <div ngbAccordionCollapse>
                                <div ngbAccordionBody>
                                    <ng-template>
                                        <p>{{ product.description }}</p>

                                        @if (product.width > 0 && product.height > 0 && product.depth > 0) {
                                            <div class="d-flex justify-content-between py-3 border-1 border-bottom">
                                                <div>Measurements</div>
                                                <div>
                                                    <span class="fw-bold">W</span> {{ product.width }}mm
                                                    <span class="fw-bold">D</span> {{ product.depth }}mm
                                                    <span class="fw-bold">H</span> {{ product.height }}mm
                                                </div>
                                            </div>
                                        }

                                        @if (product.controlType) {
                                            <div class="d-flex justify-content-between py-3 border-1 border-bottom">
                                                <div>Control Type</div>
                                                <div>{{ product.controlType }}</div>
                                            </div>
                                        }
                                        @if (product.wifi) {
                                            <div class="d-flex justify-content-between py-3 border-1 border-bottom">
                                                <div>Wifi</div>
                                                <div>Yes</div>
                                            </div>
                                        }
                                        @if (product.energyRating) {
                                            <div class="d-flex justify-content-between py-3 border-1 border-bottom">
                                                <div>Energy Rating</div>
                                                <div>{{ product.energyRating }}</div>
                                            </div>
                                        }
                                        @if (product.soundPressure) {
                                            <div class="d-flex justify-content-between py-3 border-1 border-bottom">
                                                <div>Sound Pressure</div>
                                                <div>{{ product.soundPressure }}</div>
                                            </div>
                                        }
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Next Steps</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <form>
                <div class="mb-3">
                    @if (airQuoteStore.settings()?.allowSurveyRequest) {
                        <div class="card mb-3">
                            <div class="card-body">
                                <h5 class="card-title">Home Survey</h5>
                                <p>
                                    Pick a date and time for a home survey if you would like a surveyor to give you an
                                    accurate price.
                                </p>
                                <div class="d-grid">
                                    <button
                                        type="button"
                                        class="btn btn-lg btn-success d-flex justify-content-between"
                                        (click)="airQuoteStore.openRequestSurveyModal(bundle.packId, 'home')">
                                        <fa-icon [icon]="['fas', 'house']"></fa-icon> Book Now
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="card mb-3">
                            <div class="card-body">
                                <h5 class="card-title">Virtual Survey</h5>

                                <p>Pick a date and time for a virtual call via Whatsapp or Zoom for full quotation.</p>
                                <div class="d-grid">
                                    <button
                                        type="button"
                                        class="btn btn-lg btn-success d-flex justify-content-between"
                                        (click)="airQuoteStore.openRequestSurveyModal(bundle.id, 'virtual')">
                                        <fa-icon [icon]="['fas', 'camera']"></fa-icon> Book Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    }

                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Callback</h5>

                            <p>Fill out this form and we'll give you a call back.</p>
                            <div class="d-grid">
                                <button
                                    type="button"
                                    class="btn btn-lg btn-success d-flex justify-content-between"
                                    (click)="airQuoteStore.openRequestCallbackModal(bundle.packId)">
                                    <fa-icon [icon]="['fas', 'phone']"></fa-icon> Request Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Save click')">Save</button>
        </div>
    </ng-template>
}
