import { CommonModule } from '@angular/common'
import { Component, Input, forwardRef } from '@angular/core'
import { ReactiveFormsModule, NG_VALUE_ACCESSOR } from '@angular/forms'
import { LabelComponent } from '../label/label.component'
import { ControlValueAccessorDirective } from '../../../directives/control-value-accessor.directive'

@Component({
    selector: 'sf-input-textarea',
    standalone: true,
    templateUrl: './input-textarea.component.html',
    styleUrls: ['./input-textarea.component.scss'],
    imports: [CommonModule, ReactiveFormsModule, forwardRef(() => ControlValueAccessorDirective), LabelComponent],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputTextareaComponent),
            multi: true,
        },
    ],
})
export class InputTextareaComponent<T> extends ControlValueAccessorDirective<T> {
    @Input() inputId = ''
    @Input() label = ''
    @Input() rows = 10
    @Input() customErrorMessages: Record<string, string> = {}
    @Input() placeholder = ''
    @Input() description = ''
    @Input() size: 'sm' | 'md' | 'lg' = 'md'

    // If you want to show a counter for the characters remaining, set this to the max length of the field    @Input() maxLength = 0
    @Input() maxLength = 0

    errorMessages: Record<string, string> = {
        required: 'This field is required',
        maxlength: 'This field has too many characters',
        minlength: 'This field has too many characters',
    }
}
