<div class="input-group has-validation">
    <div class="form-floating" [class.is-invalid]="control.touched && control.dirty && control.errors">
        <!-- <sf-label [label]="label" [for]="inputId" [required]="isRequired"></sf-label> -->
        <input
            #input
            class="form-control"
            [class.form-control-sm]="size == 'sm'"
            [class.form-control-lg]="size == 'lg'"
            [required]="isRequired"
            [id]="inputId"
            [formControl]="control"
            autocomplete="none"
            [readOnly]="isReadOnly"
            [placeholder]="placeholder" />
        <label [for]="inputId">{{ label + (isRequired ? '*' : '') }}</label>
    </div>
    @if (control.touched && control.dirty && control.errors) {
        @for (err of control.errors | keyvalue; track err) {
            <div class="invalid-feedback">
                {{ errorMessages[err.key] }}
            </div>
        }
    }
</div>
