import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
    selector: 'sf-label',
    standalone: true,
    imports: [],
    templateUrl: './label.component.html',
    styleUrl: './label.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelComponent {
    @Input({ required: true })
    for!: string

    @Input({ required: true })
    label!: string

    @Input()
    required: boolean = false
}
