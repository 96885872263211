<div class="container-fluid border-top border-1">
    <div class="container pt-4">
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="d-flex flex-column align-content-center">
                    <p class="mb-0 pe-3 fw-lighter fs-5">Powered by</p>
                    <a href="https://www.surefiresoftware.co.uk/" target="blank">
                        <img class="w-50 mb-2" src="./assets/images/surefire-logo.svg" />
                    </a>
                </div>
            </div>
            <div class="col-12 col-md-4"></div>
            <div class="col-12 col-md-4"></div>
        </div>
    </div>
</div>
