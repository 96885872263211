import { Component } from '@angular/core'

@Component({
    selector: 'sf-cta-mobile-app-vertical',
    standalone: true,
    imports: [],
    templateUrl: './cta-mobile-app-vertical.component.html',
    styleUrl: './cta-mobile-app-vertical.component.scss',
})
export class CtaMobileAppVerticalComponent {}
