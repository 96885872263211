import { ChangeDetectionStrategy, Component, input } from '@angular/core'

@Component({
    selector: 'ngx-loader',
    standalone: true,
    imports: [],
    templateUrl: './loader.component.html',
    styleUrl: './loader.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
    loading = input.required<boolean>()
    fixed = input<boolean>(true)
}
