<section class="page-section page-section--form">
    <div class="container" [formGroup]="form">
        <div class="row gx-5">
            <div class="col-12 col-lg-8">
                @if (bookingDetails(); as bookingDetails) {
                    <div class="row">
                        <div class="col-12">
                            <h2 class="mb-4">Thank you for your booking</h2>
                            <div class="card">
                                <div class="card-body">
                                    <p class="fw-bold fs-5 text-success">
                                        We have processed your appointment for
                                        {{ form.controls.appointmentDate.value | date }}.
                                    </p>
                                    <p>Please contact us if you need to amend your booking.</p>

                                    <hr />
                                    @if (bookingDetails.paymentUrl) {
                                        <p><i>You can pay for your appointment via Stripe.</i></p>
                                        <a sfButton href="{{ bookingDetails.paymentUrl }}">Pay Now</a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                } @else {
                    <div class="row gx-lg-5">
                        <div class="col-12">
                            <h2 class="mb-4">Book an appointment</h2>
                            <p class="mb-3">
                                Please fill in your details and select a timeslot for your appointment. We will send you
                                a confirmation email and sms to confirm your appointment.
                            </p>

                            @if (showCustomerSeachBox()) {
                                <sf-existing-customer-checker
                                    (customerFound)="
                                        populateFormForExistingCustomer($event)
                                    "></sf-existing-customer-checker>
                            }

                            @if (bookingStore.customer(); as customer) {
                                <div class="my-4">
                                    <sf-customer-card [customer]="customer"></sf-customer-card>

                                    <div class="d-grid d-sm-flex mt-2 gap-2">
                                        <button
                                            sfButton
                                            type="button"
                                            color="danger"
                                            variant="outline"
                                            (click)="removeCustomer()">
                                            These are not my details
                                        </button>
                                    </div>
                                </div>
                            }

                            <div class="mb-2">
                                <sf-label
                                    for="appointmentType"
                                    label="Select your appointment type"
                                    [required]="true"></sf-label>
                                <ng-select
                                    id="appointmentType"
                                    [items]="bookings()?.forms"
                                    bindLabel="name"
                                    bindValue="appointmentTypeId"
                                    formControlName="appointmentTypeId"
                                    [clearable]="false"
                                    placeholder="Select your appointment type">
                                </ng-select>

                                @if (
                                    form.controls.appointmentTypeId.touched && form.controls.appointmentTypeId.errors
                                ) {
                                    <p class="text-danger small">Please select an appointment type</p>
                                }
                            </div>
                        </div>
                        @if (!bookingStore.customer()) {
                            <div class="col-12 col-md-6">
                                <div class="row">
                                    <div class="col-12">
                                        <sf-postcode-lookup
                                            size="lg"
                                            label="Postcode"
                                            inputId="postcode"
                                            formControlName="postcode"
                                            [showManualInputButton]="!showAddressControls()"
                                            (onAddressChange)="setAddress($event)"
                                            (onManualInput)="setManualAddress($event)"></sf-postcode-lookup>
                                    </div>
                                    @if (showAddressControls()) {
                                        <div class="col-12">
                                            <sf-input
                                                size="lg"
                                                label="Address Line 1"
                                                inputId="addressLine1"
                                                formControlName="addressLine1"></sf-input>
                                        </div>
                                        <div class="col-12">
                                            <sf-input
                                                size="lg"
                                                label="Address Line 2"
                                                inputId="addressLine2"
                                                formControlName="addressLine2"></sf-input>
                                        </div>
                                        <div class="col-12">
                                            <sf-input
                                                size="lg"
                                                label="Address Line 3"
                                                inputId="addressLine3"
                                                formControlName="addressLine3"></sf-input>
                                        </div>
                                        <div class="col-12">
                                            <sf-input
                                                size="lg"
                                                label="Address Line 4"
                                                inputId="addressLine4"
                                                formControlName="addressLine4"></sf-input>
                                        </div>
                                        <div class="col-12">
                                            <sf-input
                                                size="lg"
                                                label="Locality"
                                                inputId="locality"
                                                formControlName="locality"></sf-input>
                                        </div>
                                        <div class="col-12">
                                            <sf-input
                                                size="lg"
                                                label="Town"
                                                inputId="town"
                                                formControlName="town"></sf-input>
                                        </div>
                                        <div class="col-12">
                                            <sf-input
                                                size="lg"
                                                label="County"
                                                inputId="county"
                                                formControlName="county"></sf-input>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="row g-2">
                                    <div class="col-12 col-sm-6">
                                        <sf-input
                                            size="lg"
                                            label="First Name"
                                            inputId="firstName"
                                            formControlName="firstName"></sf-input>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <sf-input
                                            size="lg"
                                            label="Last Name"
                                            inputId="lastName"
                                            formControlName="lastName"></sf-input>
                                    </div>
                                    <div class="col-12">
                                        <sf-input
                                            size="lg"
                                            label="Email"
                                            inputId="email"
                                            formControlName="emailAddress"></sf-input>
                                    </div>
                                    <div class="col-12">
                                        <sf-input
                                            size="lg"
                                            label="Mobile Phone"
                                            inputId="phone"
                                            formControlName="mobileNumber"></sf-input>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    @if (bookingStore.isPending()) {
                        <p>Loading available dates and timeslots...</p>
                    }

                    @if (bookingStore.availableDates()) {
                        <div class="row">
                            <div class="col-12">
                                <h4 class="mt-2 mb-3">Pick your timeslot</h4>
                            </div>
                            @if (loadingTimeSlots()) {
                                <div class="col-12">
                                    <p>Loading available timeslots...</p>
                                </div>
                            }
                            <div class="col-12">
                                <div class="row">
                                    @if (bookingStore.enabledDates()?.length === 0) {
                                        <div class="col-12">
                                            <ngb-alert type="danger" [dismissible]="false">
                                                <h4>Sorry!</h4>
                                                <p class="mb-0">
                                                    We do not have any available dates for this postcode area. Please
                                                    get in touch via phone or email and send a general enquiry.
                                                </p>
                                            </ngb-alert>
                                        </div>
                                    }
                                    @if (bookingStore.enabledDates()?.length) {
                                        <div class="col-sm-6 text-center">
                                            <ngb-datepicker
                                                #dp
                                                id="date"
                                                (dateSelect)="selectDate($event)"
                                                [dayTemplate]="t"
                                                [markDisabled]="isDisabled"
                                                [minDate]="bookingStore.minDate()"
                                                [maxDate]="bookingStore.maxDate()"
                                                outsideDays="collapsed"
                                                weekdays="short"
                                                navigation="arrows" />
                                            <ng-template
                                                #t
                                                let-date
                                                let-disabled="disabled"
                                                let-focused="focused"
                                                let-selected="selected">
                                                <span
                                                    class="custom-day"
                                                    [class.selected]="selected"
                                                    [class.focused]="focused"
                                                    [class.hovered]="date === hoveredDate"
                                                    [class.available]="isDateEnabled(date)"
                                                    [class.disabled]="disabled"
                                                    (mouseenter)="hoveredDate = date"
                                                    (mouseleave)="hoveredDate = null">
                                                    {{ date.day }}
                                                </span>
                                            </ng-template>
                                        </div>
                                        <div class="col-sm-6">
                                            @if (bookingStore.availableTimes(); as availableTimes) {
                                                <sf-label
                                                    class="d-block mt-1"
                                                    for="date"
                                                    label="{{
                                                        form.controls.appointmentDate.value | date: 'EEEE d MMMM y'
                                                    }}"
                                                    [required]="true"></sf-label>

                                                <div class="d-grid gap-2">
                                                    @for (
                                                        time of availableTimes.availableStartTimes;
                                                        track time.availableStartTime
                                                    ) {
                                                        <button
                                                            class="btn btn-outline-primary"
                                                            [class.active]="
                                                                time.availableStartTime ===
                                                                form.controls.appointmentDate.value
                                                            "
                                                            (click)="selectTime(time)">
                                                            {{ time.availableStartTime | date: 'HH:mm a' }}
                                                        </button>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    <div class="row">
                        <div class="col-12 mt-4">
                            <sf-input-textarea
                                [rows]="5"
                                [maxLength]="1000"
                                placeholder="If you have any specific requirements, please let us know."
                                label="Notes"
                                inputId="notes"
                                formControlName="notes"></sf-input-textarea>
                        </div>
                        @if (form.invalid && form.touched) {
                            <div class="col-12 mt-3">
                                <ngb-alert type="danger" [dismissible]="false">
                                    <p class="mb-0">
                                        Please ensure all required fields are completed and you have selected a timeslot
                                        date & time.
                                    </p>
                                </ngb-alert>
                            </div>
                        }
                        <div class="col-12">
                            <div class="d-grid my-2">
                                <button
                                    sfButton
                                    type="button"
                                    color="primary"
                                    size="lg"
                                    [disabled]="form.invalid"
                                    (click)="submit()">
                                    Book Appointment
                                </button>
                            </div>
                            <a sfButton [routerLink]="" (click)="resetForm()" color="dark" variant="outline"
                                >Reset Form</a
                            >
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
</section>
