import { inject } from '@angular/core'
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router'
import { ConfigService } from '../../services/config.service'

export const authGuard: CanActivateFn = (route, state) => {
    const configService = inject(ConfigService)
    const router = inject(Router)
    const hasApiKey = configService.apiKey() !== null

    if (!hasApiKey) {
        return router.navigate(['/not-found'])
    }

    return true
}

export const authChildGuard: CanActivateChildFn = (route, state) => authGuard(route, state)
